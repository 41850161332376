import { PDFDocument } from 'pdf-lib';

export const utilsPDF = async (file, setPdfInfo, setFieldValue, name, setErrorMessage, cropmark, desiredWidth, desiredHeight) => {
  const reader = new FileReader();
  reader.onload = async (e) => {
    try {
      const pdfBuffer = e.target.result;
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const pages = pdfDoc.getPages();
      const pageInfo = await Promise.all(pages.map((page, index) => extractPageInfo(page, index)));

      setPdfInfo(pageInfo);
      setFieldValue(name, file);  // Optionally update the Formik field
      handleCropMarks(pageInfo, setErrorMessage, cropmark, desiredWidth, desiredHeight);
    } catch (error) {
      console.error("Error processing PDF file:", error);
      setErrorMessage("Failed to load or process PDF file.");
    }
  };
  reader.readAsArrayBuffer(file);
};

const extractPageInfo = async (page, index) => {
  const { width, height } = page.getSize();
  const dpi = 72;  // Standard DPI for PDFs in points per inch
  const widthInches = width / dpi;
  const heightInches = height / dpi;

  // Convert inches to centimeters
  const widthCm = (widthInches * 2.54).toFixed(2);
  const heightCm = (heightInches * 2.54).toFixed(2);

  const annotations = await extractAnnotations(page);

  return {
    pageIndex: index + 1,
    widthPx: width,
    heightPx: height,
    widthCm,
    heightCm,
    dpiWidth: dpi,
    dpiHeight: dpi,
    annotations
  };
};

const extractAnnotations = async (page) => {
  const annotations = [];
  const annotsRefs = page.node.Annots();
  if (annotsRefs) {
    const annotsArray = await page.doc.context.lookup(annotsRefs);
    if (Array.isArray(annotsArray)) {
      for (const ref of annotsArray) {
        const annotationDict = await page.doc.context.lookup(ref);
        annotations.push(annotationDict.toString());  // Adjust this line as needed
      }
    }
  }
  return annotations;
};

const handleCropMarks = (pageInfo, setErrorMessage, cropmark, desiredWidth, desiredHeight) => {
  if (cropmark) {
    const results = pageInfo.map(info => {
      const actualWidth = Math.round(parseFloat(info.widthCm) * 100) / 100;
      const actualHeight = Math.round(parseFloat(info.heightCm) * 100) / 100;

      const minWidth = desiredWidth;
      const minHeight = desiredHeight;
      const maxWidth = desiredWidth + 2;
      const maxHeight = desiredHeight + 2;

      console.log(`Actual Width: ${actualWidth}, Actual Height: ${actualHeight}`);

      // ✅ Exact size — Missing crop marks (orange)
      if (actualWidth === minWidth && actualHeight === minHeight) {
        return (
          <div className="warn-no-crop warn-no-crop--missing">
            ⚠️ Vermutlich fehlende Schnittmarken – bitte überprüfe das PDF nochmals.<br />
            Erwartet: {desiredWidth} cm x {desiredHeight} cm<br />
            Tatsächlich: {actualWidth} cm x {actualHeight} cm
          </div>
        );
      }

      // ✅ Within 2 cm tolerance — Correct (green)
      if (
        actualWidth >= minWidth &&
        actualHeight >= minHeight &&
        actualWidth <= maxWidth &&
        actualHeight <= maxHeight
      ) {
        return (
          <div className="warn-no-crop warn-no-crop--correct">
            ✅ Korrektes Format hochgeladen.<br />
            Erwartet: {desiredWidth} cm x {desiredHeight} cm<br />
            Tatsächlich: {actualWidth} cm x {actualHeight} cm
          </div>
        );
      }

      // ❌ Smaller than desired size — Wrong format (red)
      if (actualWidth < minWidth || actualHeight < minHeight) {
        return (
          <div className="warn-no-crop warn-no-crop--error">
            ❌ Falsches Format hochgeladen.<br />
            Erwartet: {desiredWidth} cm x {desiredHeight} cm<br />
            Tatsächlich: {actualWidth} cm x {actualHeight} cm
          </div>
        );
      }

      // ❌ More than 2 cm larger — Wrong format (red)
      if (actualWidth > maxWidth || actualHeight > maxHeight) {
        return (
          <div className="warn-no-crop warn-no-crop--error">
            ❌ Falsches Format hochgeladen – vermutlich zu groß angelegt.<br />
            Erwartet: {desiredWidth} cm x {desiredHeight} cm<br />
            Tatsächlich: {actualWidth} cm x {actualHeight} cm
          </div>
        );
      }

      return null;
    });

    // ✅ Remove duplicate messages by using `Set`
    const uniqueResults = [...new Set(results.filter(message => message !== null))];

    // ✅ Pass JSX instead of a string to `setErrorMessage`
    if (uniqueResults.length > 0) {
      setErrorMessage(<>{uniqueResults}</>);
    } else {
      setErrorMessage(null);
    }
  }
};
