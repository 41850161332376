import React, { useState } from 'react';
import { Button } from 'react-bootstrap'; // ✅ Hier kommt der Button her
import LLM_CONFIG from '../../config/llmConfig';
import ReactMarkdown from 'react-markdown';

const LlmFeedbackGroqTemplate = ({ values, PromptTemplate, extraPrompt = '' }) => {
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);


  const handleSend = async () => {
    const { systemPrompt, userPrompt } = PromptTemplate({ values, extraPrompt });

    if (!userPrompt || !systemPrompt) {
      setError('❌ Prompt konnte nicht erstellt werden.');
      return;
    }

    const messages = [
      { role: 'system', content: systemPrompt },
      { role: 'user', content: userPrompt },
    ];

    setLoading(true);
    setError('');
    setFeedback('');

    try {
      const response = await fetch(LLM_CONFIG.API_URL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${LLM_CONFIG.API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: LLM_CONFIG.MODEL,
          messages,
          temperature: LLM_CONFIG.TEMPERATURE,
        }),
      });

      const data = await response.json();
      const reply = data.choices?.[0]?.message?.content;

      if (reply) {
        setFeedback(reply);
      } else if (data.error) {
        setError(`❌ API Fehler: ${data.error.message}`);
      } else {
        setError('❌ Keine Antwort erhalten.');
      }
    } catch (err) {
      console.error('Groq error', err);
      setError('⚠️ Fehler beim Abrufen der Rückmeldung.');
    } finally {
      setLoading(false);
    }
  };
  const { systemPrompt, userPrompt } = PromptTemplate({ values, extraPrompt });


  return (
    <div>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Button variant="outline-secondary" onClick={handleSend} disabled={loading}>
          {loading ? 'Lade Feedback...' : 'Groq: Feedback generieren'}
        </Button>
  
        <Button
          variant="outline-info"
          onClick={() => setShowPrompt(prev => !prev)}
        >
          {showPrompt ? 'Prompt ausblenden' : 'Prompt anzeigen'}
        </Button>
      </div>
  
      {error && (
        <div style={{ color: 'red', marginTop: '1rem' }}>
          {error}
        </div>
      )}
  
      {showPrompt && (
        <div style={{ marginTop: '1rem', padding: '1rem', background: '#f8f9fa', borderRadius: '6px' }}>
          <h5>System Prompt</h5>
          <pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.85rem' }}>{systemPrompt}</pre>
  
          <h5>User Prompt</h5>
          <pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.85rem' }}>{userPrompt}</pre>
        </div>
      )}
  
      {feedback && (
        <div style={{ marginTop: '1rem', border: '1px solid #ddd', padding: '1rem', borderRadius: '6px', background: '#fefefe' }}>
          <ReactMarkdown>{feedback}</ReactMarkdown>
        </div>
      )}
    </div>
  );
  
};

export default LlmFeedbackGroqTemplate;
