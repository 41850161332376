import React from 'react';
import '../index.css'; // Import the CSS file for styling
import DPICalculator from './comp-dpi-calc';
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormSelect,
  Accordion,
  Card,
  Tab,
  Tabs,
  Row,
  Col,
} from "react-bootstrap";

const ResolutionDisplay = ({ resolution, paperWidthCM, minWidth = 1920 }) => { // Set a default minWidth
  if (!resolution) {
    return <p></p>
  }

  const isLowResolution = resolution.width < minWidth;
  const resolutionStyle = isLowResolution ? 'resolution-display low-resolution' : 'resolution-display';
  console.log("Resolution:", resolution);
  console.log("paperWidthCM:", paperWidthCM);
  return (
    <div className={resolutionStyle}>
      <p>Auflösung: {resolution.width}px x {resolution.height}px</p>
      {isLowResolution && (
        <p className="warning"><h4>Achtung!</h4>
          Das ausgewählte Bild erfüllt nicht die Mindestvoraussetzungen.
          Die Breite des Bildes sollte mindestens über {minWidth} Pixel verfügen.
        </p>
      )}


      <DPICalculator
        pixelWidth={resolution.width}
        pixelHeight={resolution.height}
        paperWidthCm={paperWidthCM}
      />
    </div>
  );
};

export default ResolutionDisplay;
