import React from 'react';



const stripHtml = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const formatTags = (tags) =>
  tags
    ?.filter((tag) => tag.value)
    .map((tag) => `#${tag.value.replace(/\s+/g, '')}`)
    .join(' ');

const LlmTempPromptKurzb = ({ values, extraPrompt = '' }) => {
  const {
    projektart = {},
    persondata = [],
    assignedLehrende = [],
    semestertype = {},
    jahr = '',
    selecStgType = '',
    selecStg = {},
    ger_project_tags = [],
    ger_descr_projekt_short = '',
    ger_descr_projekt_long = '',
  } = values;

  const systemPrompt = `

Du bist ein akademischer Feedbackgeber mit Fokus auf künstlerisch-gestalterische Projekte. 
Antworte ausschließlich auf Deutsch. Gib präzise Rückmeldungen aus einer reflektierten, kunstwissenschaftlichen Perspektive.
Achte dabei auf Aspekte wie Konzeptschärfe, künstlerische Haltung, gestalterische Umsetzung und Originalität. Dein Feedback soll sich auf die Kurzbeschreibung und deren Inahtl beziehen.
Wie kann der Text verbessert werden? Wo siehst du schwachstellen? Berücksichtige dabei auch die weiteren Informationen wie Tags, Semester, Studierende und Lehrende.
Formuliere in einem sachlich-konstruktiven Ton und gib konkrete Verbesserungsvorschläge. Am Ende Liste die erhaltenen Informationen auf mit welchen du gearbeitet hast.
Erstelle ein überarbeitet Version der Kurzbeschreibung`.trim();

  const userPrompt = `
Projekt: ${projektart?.valuefull}

Betreuung:
${assignedLehrende.map((l, i) => 
  `• ${l.lehrende || l.label || ''}${l.ger_lehrgebiet ? ` – ${l.ger_lehrgebiet}` : ''}${l.ger_descr_lehrgebiet ? `\n  ${stripHtml(l.ger_descr_lehrgebiet)}` : ''}`
).join('\n')}

Semester: ${semestertype?.value} ${jahr} / ${selecStgType} ${selecStg?.valuefull}

Tags: ${formatTags(ger_project_tags)}

Kurzbeschreibung:
${stripHtml(ger_descr_projekt_short)}

Ausführliche Beschreibung:
${stripHtml(ger_descr_projekt_long)}

${extraPrompt}
`.trim();

  return { systemPrompt, userPrompt };
};

export default LlmTempPromptKurzb;
