// PDFAccordion.js
import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import FormFileSelectPDF from '../form-file-pdf';

const AccDateienPDF = ({ values, imgnaming, countarrayDispatch, pdfDispatch, pdfState, countarrayState }) => {
  const {
    projektart = {},
    persondata = [],
    assignedLehrende = [],
    semestertype = {},
    jahr = '',
    selecStgType = '',
    selecStg = {},
    ger_project_tags = [],
    eng_project_tags = [],
    ger_descr_projekt_short = '',
    eng_descr_projekt_short = '',
    ger_descr_projekt_long = '',
    eng_descr_projekt_long = ''
} = values;

  return (
    <Accordion flush>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <h2>PDF Dateien</h2>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col>
            
            {/* {Object.entries(selecStg).map(([key, val]) => (
  <div key={key}>
    <strong>{key}</strong>: {String(val)}
  </div>
))} */}

{/* {semestertype?.value} {jahr} / {typeof selecStgType === 'string' ? selecStgType.charAt(0).toUpperCase() + selecStgType.slice(1) : ''} {selecStg?.valuefull} */}
              <FormFileSelectPDF
                selecStg={selecStg}
                displayStg={['KD', 'AR', 'MD']}
                amount_input_field={1}
                label="PDF Plakat"
                name="pdf_plakat"
                imgnaming={imgnaming}
                desiredWidth={70}
                desiredHeight={100}
                unit="cm"
                dispatch={pdfDispatch}
                countarrayDispatch={countarrayDispatch}
                countarrayState={countarrayState}
                pdfState={pdfState}
                cropmark={true}
                enableAddButton={false}
              />

              <FormFileSelectPDF
                selecStg={selecStg}
                displayStg={['KD']}
                amount_input_field={1}
                label="Präsentation"
                name="pdf_presentation"
                imgnaming={imgnaming}
                desiredWidth={1920}
                desiredHeight={1080}
                unit="px"
                dispatch={pdfDispatch}
                countarrayDispatch={countarrayDispatch}
                countarrayState={countarrayState}
                pdfState={pdfState}
                cropmark={false}
                enableAddButton={false}
              />

              <FormFileSelectPDF
                selecStg={selecStg}
                displayStg={['KD', 'AR', 'MD']}
                amount_input_field={1}
                label="Weiteres PDF"
                name="pdf_additional"
                imgnaming={imgnaming}
                desiredWidth={1920}
                desiredHeight={1080}
                unit="px"
                dispatch={pdfDispatch}
                countarrayDispatch={countarrayDispatch}
                countarrayState={countarrayState}
                pdfState={pdfState}
                cropmark={false}
                enableAddButton={true}
              />
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccDateienPDF;
