import React from 'react';
import { Accordion, Button, Row, Col, FormControl } from 'react-bootstrap';



const StaffAnleitung = ({ }) => {
 
    return (
<Accordion flush className="info" >
<Accordion.Item eventKey="0">
  <Accordion.Header>
    <h4>Hinweise / Anleitung</h4>
  </Accordion.Header>
  <Accordion.Body>
    <Row>
      <Col>
      <ul>
      <li><h4>Hinweise</h4></li>
      <li><bold>Achtung! sobald eine neue Seite aufgerufen wird, erlischt das Formular und Ihre bereits gemachten Angaben gehen verloren.</bold></li>
      <li>Bitte beachten Sie, dass für jede Professur bereits eine Datei angelegt wurde. Bitte bearbeiten Sie diese und ergänzen die fehlenden Informationen!</li>
      <li>Achten Sie darauf auch die Bilddateien hochzuladen. Es ist notwendig die Bilddateien zuvor in dem Formular hochzuladen, um die korrekten Dateibenennungen zu erzeugen, die in der weiteren Verarbeitung eine hohe Relevanz haben!</li>
      <li>Laden Sie also ausschließlich die Datein in den Upload-Ordner, die zurvor über das Formular erzeugt wurden!</li>
      <li>Sollten Sie irgendwelche Angaben in den Auswahlfelder vermissen, oder auch Datenfelder fehlen,
teilen Sie uns dies bitte über das Kontaktformular. Sie finden das Kontaktformular in der oberen Leiste unter "Kontakt" Vielen Dank!</li>
    </ul>
      </Col>
      <Col>
      <h4>Anleitung</h4>

<div class="container">
<div class="form-container">
<div class="checkbox-container">
<input type="checkbox" id="check1"/>
<label class="checkbox" for="check1">
1. Vorlage-Datei prüfen und herunterladen
<p>Prüfen Sie unter "Vorlage herunterladen", ob für Sie bereits eine Vorlage angelegt wurde. Sollte dies nicht der Fall sein springen Sie direkt zu Punkt 3.
<br></br><a href="https://seafile.rlp.net/d/9f4bd209cb984fb6aa58/" target="_blank">Laden Sie die Vorlage auf Seafile gegebenenfalls herunter.</a><br></br>
Die Datei wird lokal auf ihrem Computer gespeichert (z.B. im Download-Ordner).
</p>
</label>
</div>
<div class="checkbox-container">
<input type="checkbox" id="check2"/>
<label class="checkbox" for="check2">
2. Datei importieren<br>
</br>
<p>
</p>
</label>
</div>
<div class="checkbox-container">
<input type="checkbox" id="check3"/>
<label class="checkbox" for="check3">
3. Formular ausfüllen<br></br>
<p>Geben Sie die Daten entsprechend ein. Beachten Sie die Hinweise dazu weiter unten.</p>
</label>
</div>

<div class="checkbox-container">
<input type="checkbox" id="check4"/>
<label class="checkbox" for="check4">
4. Datei lokal speichern<br></br>
<p>Anschließend exportieren Sie ihre Eingaben, womit sie diese auf Ihrem Gerät ablegen. Insgesamt werden drei Dateien exportiert. 1x .json Datei und 2x Bilddateien. Denken Sie daran die Dateien für eine mögliche Weiterarbeitung in ihrem Ordnersystem abzulegen.</p>
</label>
</div>
<div class="checkbox-container">
<input type="checkbox" id="check5"/>
<label class="checkbox" for="check5">
5. Daten hochladen<br></br>
<p>Laden Sie ihre bearbeiteten Datei unter dem Gliederungspunkt "Daten hochladen" auf Seafile hoch.</p>
</label>
</div>
</div>
</div>
      </Col>
    </Row>
  </Accordion.Body>
</Accordion.Item>
</Accordion>
    );
};

export default StaffAnleitung;
