import { useState } from 'react';
import { useFormikContext } from 'formik';
import { utilsPDF } from '../pdflib/utils-pdf';

export const useFileHandler = (name, cropmark, unit, desiredWidth, desiredHeight, dispatch = () => {}) => {
    const { setFieldValue } = useFormikContext();
    const [fileInfo, setFileInfo] = useState({ originalFilename: '', metadata: null });
    const [errorMessage, setErrorMessage] = useState("");

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            setErrorMessage("No file selected.");
            console.error("No file selected.");
            return;
        }

        try {
            const url = URL.createObjectURL(file);
            console.log('Created URL for file:', url);
            setFieldValue(name, file);
        
            const fileType = file.type.split('/')[0];
            // console.log('File type:', fileType);
            // console.log(`Passing to utilsPDF: desiredWidth=${desiredWidth}, desiredHeight=${desiredHeight}`);
        
            // console.log(`[useFileHandler] desiredHeight type: ${typeof desiredHeight}`);
            // console.log(`[useFileHandler] desiredHeight value:`, desiredHeight);


            if (fileType === "application" && file.type === "application/pdf") {
                console.log('Processing PDF file:', file.name);
                dispatch({ type: 'SET_PDF_URL', fieldName: name, varUrl: url });
        
                await utilsPDF(
                    file,
                    (info) => {
                        setFileInfo({
                            originalFilename: file.name,
                            metadata: { pages: info }
                        });
                    },
                    setFieldValue,
                    name,
                    setErrorMessage,
                    cropmark,
                    desiredWidth,
                    desiredHeight // Check if this value is undefined or null!
                );
            } else if (fileType === "image") {
                // console.log('Processing image file:', file.name);
                dispatch({ type: 'SET_IMG_URL', fieldName: name, varUrl: url });

                const metadata = await extractImageMetadata(file);
                
                // Dispatch metadata immediately after extraction
                dispatch({
                    type: 'SET_IMG_METADATA',
                    fieldName: name,
                    metadata: metadata,
                    feedbackMessage: metadata.width >= desiredWidth && metadata.height >= desiredHeight
                        ? `Good job! The uploaded image meets the minimum required dimensions of ${desiredWidth}${unit} x ${desiredHeight}${unit}.`
                        : `The image does not meet the minimum size requirements of ${desiredWidth}${unit} x ${desiredHeight}${unit}. Current size is ${metadata.width}${unit} x ${metadata.height}${unit}.`
                });

                setFileInfo({
                    originalFilename: file.name,
                    metadata: metadata
                });

                // Check if resolution meets requirements
                if (metadata.width !== desiredWidth || metadata.height !== desiredHeight) {
                    setErrorMessage(`Image must be ${desiredWidth}${unit} x ${desiredHeight}${unit}.`);
                } else {
                    setErrorMessage(""); // Clear any previous errors if dimensions are correct
                }
            } else {
                console.log('Processing non-image and non-PDF file:', file.name);
                dispatch({ type: 'SET_VAR_FILE_URL', fieldName: name, varUrl: url });
                setFileInfo({
                    originalFilename: file.name,
                    metadata: null // No metadata for non-image and non-PDF files
                });
            }

            setErrorMessage(""); // Clear any previous error messages
        } catch (error) {
            console.error("Error processing file:", error);
            setErrorMessage("Failed to process file.");
        }
    };

    return { handleFileChange, fileInfo, errorMessage };
};

// Exporting extractImageMetadata function so it can be used in other files
export async function extractImageMetadata(file) {
    console.log('Extracting metadata for file:', file.name);
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height
            });
        };
        img.onerror = (error) => {
            console.error('Failed to load image metadata:', error);
            reject(new Error("Failed to load image metadata"));
        };
        img.src = URL.createObjectURL(file);
    });
}
