export const selecStgMap = {
  "AR\\staff-AR-Prof-Andrea-Wandel-.json": {
    "value1": "AR\\staff-AR-Prof-Andrea-Wandel-.json",
    "value2": "Entwerfen, Raumbildung und Darstellung",
    "fullname": "Prof. Andrea Wandel",
    "selecStg": "AR"
  },
  "AR\\staff-AR-Prof-Christoph-Klanten-.json": {
    "value1": "AR\\staff-AR-Prof-Christoph-Klanten-.json",
    "value2": "Städtebau",
    "fullname": "Prof. Christoph Klanten",
    "selecStg": "AR"
  },
  "AR\\staff-AR-Prof-Daniel-Berger-.json": {
    "value1": "AR\\staff-AR-Prof-Daniel-Berger-.json",
    "value2": "Konstruieren und Entwerfen",
    "fullname": "Prof. Daniel Berger",
    "selecStg": "AR"
  },
  "AR\\staff-AR-Prof-Jan-Henrik-Hafke-.json": {
    "value1": "AR\\staff-AR-Prof-Jan-Henrik-Hafke-.json",
    "value2": "Entwerfen und Gebäudelehre",
    "fullname": "Prof. Jan-Henrik Hafke",
    "selecStg": "AR"
  },
  "AR\\staff-AR-Prof-Matthias-Sieveke-.json": {
    "value1": "AR\\staff-AR-Prof-Matthias-Sieveke-.json",
    "value2": "Konstruktion und Gebäudetechnologie",
    "fullname": "Prof. Matthias Sieveke",
    "selecStg": "AR"
  },
  "AR\\staff-AR-Prof-Michael-Schwarz-.json": {
    "value1": "AR\\staff-AR-Prof-Michael-Schwarz-.json",
    "value2": "Baudenkmalpflege",
    "fullname": "Prof. Michael Schwarz",
    "selecStg": "AR"
  },
  "AR\\staff-AR-Prof-Petra-Riegler-Floors-.json": {
    "value1": "AR\\staff-AR-Prof-Petra-Riegler-Floors-.json",
    "value2": "Zirkuläres Bauen, Konsturktion und Material",
    "fullname": "Prof. Petra Riegler-Floors",
    "selecStg": "AR"
  },
  "AR\\staff-AR-Prof-Robert-Thum-.json": {
    "value1": "AR\\staff-AR-Prof-Robert-Thum-.json",
    "value2": "Computational Design in Architecture",
    "fullname": "Prof. Robert Thum",
    "selecStg": "AR"
  },
  "ES\\staff-ES-Prof-Eva-Maria-Kollischan.json": {
    "value1": "ES\\staff-ES-Prof-Eva-Maria-Kollischan.json",
    "value2": "künstlerische Gestaltung",
    "fullname": "Prof. Eva-Maria Kollischan",
    "selecStg": "ES"
  },
  "ES\\staff-ES-Prof-Theo-Smeets-.json": {
    "value1": "ES\\staff-ES-Prof-Theo-Smeets-.json",
    "value2": "Schmuckgestaltung",
    "fullname": "Prof. Theo Smeets",
    "selecStg": "ES"
  },
  "ES\\staff-ES-Prof-Ute-Eitzenhöfer-.json": {
    "value1": "ES\\staff-ES-Prof-Ute-Eitzenhöfer-.json",
    "value2": "Edelsteingestaltung",
    "fullname": "Prof. Ute Eitzenhöfer",
    "selecStg": "ES"
  },
  "IA\\staff-IA-Prof-Dirk Miguel-Schluppkotten-.json": {
    "value1": "IA\\staff-IA-Prof-Dirk Miguel-Schluppkotten-.json",
    "value2": "N/A",
    "fullname": "Prof. Dirk Miguel Schluppkotten",
    "selecStg": "IA"
  },
  "IA\\staff-IA-Prof-Ingo-Krapf-.json": {
    "value1": "IA\\staff-IA-Prof-Ingo-Krapf-.json",
    "value2": "Produkt-, Möbel und Ausstellungsdesign PMAD",
    "fullname": "Prof. Ingo Krapf",
    "selecStg": "IA"
  },
  "IA\\staff-IA-Prof-Kirsi Susanna-Leppäkoski-.json": {
    "value1": "IA\\staff-IA-Prof-Kirsi Susanna-Leppäkoski-.json",
    "value2": "Innenarchitektur und Narrativer Raum",
    "fullname": "Prof. Kirsi Susanna Leppäkoski",
    "selecStg": "IA"
  },
  "IA\\staff-IA-Prof-Marcus-Kaiser-.json": {
    "value1": "IA\\staff-IA-Prof-Marcus-Kaiser-.json",
    "value2": "Innenarchitektonisches Konstruieren und Bauen im Bestand",
    "fullname": "Prof. Marcus Kaiser",
    "selecStg": "IA"
  },
  "IA\\staff-IA-Prof-Martin-Schroth-.json": {
    "value1": "IA\\staff-IA-Prof-Martin-Schroth-.json",
    "value2": "Digitales Konstruieren und Entwerfen",
    "fullname": "Prof. Martin Schroth",
    "selecStg": "IA"
  },
  "IA\\staff-IA-Prof-Stefan-Dornbusch-.json": {
    "value1": "IA\\staff-IA-Prof-Stefan-Dornbusch-.json",
    "value2": "Zeichnen und Gestaltungsgrundlagen ZUGG",
    "fullname": "Prof. Stefan Dornbusch",
    "selecStg": "IA"
  },
  "ID\\staff-ID-Prof-Daniel-Gilgen-.json": {
    "value1": "ID\\staff-ID-Prof-Daniel-Gilgen-.json",
    "value2": "Medienräume, Medial Raum und Umweltgestaltung",
    "fullname": "Prof. Daniel Gilgen",
    "selecStg": "ID"
  },
  "ID\\staff-ID-Prof-Gregor-Kuschmirz-.json": {
    "value1": "ID\\staff-ID-Prof-Gregor-Kuschmirz-.json",
    "value2": "Intermediale Ästhetik",
    "fullname": "Prof. Gregor Kuschmirz",
    "selecStg": "ID"
  },
  "ID\\staff-ID-Prof-Linda-Breitlauch-.json": {
    "value1": "ID\\staff-ID-Prof-Linda-Breitlauch-.json",
    "value2": "Games und 3D",
    "fullname": "Prof. Linda Breitlauch",
    "selecStg": "ID"
  },
  "ID\\staff-ID-Prof-Servet-Golbol-.json": {
    "value1": "ID\\staff-ID-Prof-Servet-Golbol-.json",
    "value2": "Narrative Formate",
    "fullname": "Prof. Servet Golbol",
    "selecStg": "ID"
  },
  "MD\\staff-IA-MD-Prof-Christina-Threuter-.json": {
    "value1": "MD\\staff-IA-MD-Prof-Christina-Threuter-.json",
    "value2": "Design-, Kunst- und Kulturgeschichte",
    "fullname": "Prof. Christina Threuter",
    "selecStg": "IA-MD"
  },
  "MD\\staff-MD-Prof-Bettina-Maiburg-.json": {
    "value1": "MD\\staff-MD-Prof-Bettina-Maiburg-.json",
    "value2": "Grundlagen Gestaltung und Aufbau von Bekleidungskollektionen, Grundlagen künstlerischer Gestaltung, Kollektionserstellung und –planung, DOB, KIKO",
    "fullname": "Prof. Bettina Maiburg",
    "selecStg": "MD"
  },
  "MD\\staff-MD-Prof-Christian-Bruns-.json": {
    "value1": "MD\\staff-MD-Prof-Christian-Bruns-.json",
    "value2": "Digitale Grundlagen Modedesign und 3D Modedesign-Prototyping",
    "fullname": "Prof. Christian Bruns",
    "selecStg": "MD"
  },
  "MD\\staff-MD-Prof-Dirk-Wolfes-.json": {
    "value1": "MD\\staff-MD-Prof-Dirk-Wolfes-.json",
    "value2": "Grundlagen Gestaltung und Aufbau von Bekleidungskollektionen, Grundlagen künstlerischer Gestaltung, gestalterischer Entwurf, Kollektionsgestaltung und-planung",
    "fullname": "Prof. Dirk Wolfes",
    "selecStg": "MD"
  },
  "MD\\staff-MD-Prof-Sarah-Weisman-.json": {
    "value1": "MD\\staff-MD-Prof-Sarah-Weisman-.json",
    "value2": "Grundlagen figürliches und freies Zeichnen, künstlerische Techniken und plastisches Gestalten",
    "fullname": "Prof. Sarah Weisman",
    "selecStg": "MD"
  },
  "staff_kd\\staff-Empl-KD-Achim-Andres_1716292523.json": {
    "value1": "staff_kd\\staff-Empl-KD-Achim-Andres_1716292523.json",
    "value2": "N/A",
    "fullname": "Achim Andres",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Empl-KD-Frank-Thieltges_1716294851.json": {
    "value1": "staff_kd\\staff-Empl-KD-Frank-Thieltges_1716294851.json",
    "value2": "N/A",
    "fullname": "Frank Thieltges",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Lect-KD-Michael-Zirlewagen_1716294114.json": {
    "value1": "staff_kd\\staff-Lect-KD-Michael-Zirlewagen_1716294114.json",
    "value2": "N/A",
    "fullname": "Michael Zirlewagen",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Lect-KD-Nicholas-Tänzer_1716292829.json": {
    "value1": "staff_kd\\staff-Lect-KD-Nicholas-Tänzer_1716292829.json",
    "value2": "N/A",
    "fullname": "Nicholas Tänzer",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Prof-KD-Babak Mossa-Asbagholmodjahedin_1716292918.json": {
    "value1": "staff_kd\\staff-Prof-KD-Babak Mossa-Asbagholmodjahedin_1716292918.json",
    "value2": "Zeichnen und Medien",
    "fullname": "Prof. Babak Mossa Asbagholmodjahedin",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Prof-KD-Christopher-Ledwig_1716292973.json": {
    "value1": "staff_kd\\staff-Prof-KD-Christopher-Ledwig_1716292973.json",
    "value2": "Digitale Medien",
    "fullname": "Prof. Christopher Ledwig",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Prof-KD-Dirk-Wachowiak_1716293050.json": {
    "value1": "staff_kd\\staff-Prof-KD-Dirk-Wachowiak_1716293050.json",
    "value2": "Typografie und Corporate Design",
    "fullname": "Prof. Dirk Wachowiak",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Prof-KD-Harald-Steber_1716293207.json": {
    "value1": "staff_kd\\staff-Prof-KD-Harald-Steber_1716293207.json",
    "value2": "3DKD - Dreidimensionales Kommunikationsdesign",
    "fullname": "Prof. Harald Steber",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Prof-KD-Henriette-Sauvant_1716304595.json": {
    "value1": "staff_kd\\staff-Prof-KD-Henriette-Sauvant_1716304595.json",
    "value2": "Buchillustration und Buchgestaltung",
    "fullname": "Prof. Henriette Sauvant",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Prof-KD-IA-Gerald-Schröder_1716292668.json": {
    "value1": "staff_kd\\staff-Prof-KD-IA-Gerald-Schröder_1716292668.json",
    "value2": "Design und Kunstwissenschaft",
    "fullname": "Prof. Dr. habil. Gerald Schröder",
    "selecStg": "KD-IA"
  },
  "staff_kd\\staff-Prof-KD-Tim-Romanowsky_1716292395.json": {
    "value1": "staff_kd\\staff-Prof-KD-Tim-Romanowsky_1716292395.json",
    "value2": "Experimentelle Gestaltung",
    "fullname": "Prof. Tim Romanowsky",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Prof-KD-Valerie-Schmidt_1716293503.json": {
    "value1": "staff_kd\\staff-Prof-KD-Valerie-Schmidt_1716293503.json",
    "value2": "Transmediale fotografische Narration und Bildgestaltung",
    "fullname": "Prof. Valerie Schmidt",
    "selecStg": "KD"
  },
  "staff_kd\\staff-Prof-TD-ID-MD-AR-KD-Susanne-Bennewitz_1716293651.json": {
    "value1": "staff_kd\\staff-Prof-TD-ID-MD-AR-KD-Susanne-Bennewitz_1716293651.json",
    "value2": "Gesellschafts- und Kulturwissenschaften",
    "fullname": "Prof. Dr.  Susanne Bennewitz",
    "selecStg": "TD-ID-MD-AR-KD"
  },
  "TD\\staff-TD-Prof-Jörg-Obergfell-.json": {
    "value1": "TD\\staff-TD-Prof-Jörg-Obergfell-.json",
    "value2": "Künstlerisches Gestalten",
    "fullname": "Prof. Jörg Obergfell",
    "selecStg": "TD"
  },
  "TD\\staff-TD-Prof-Simon-Maris-.json": {
    "value1": "TD\\staff-TD-Prof-Simon-Maris-.json",
    "value2": "KI - Design und Nachhaltigkeit",
    "fullname": "Prof. Simon Maris",
    "selecStg": "TD"
  },
  "TD\\staff-TD-Prof-Susanne-Bennewitz-.json": {
    "value1": "TD\\staff-TD-Prof-Susanne-Bennewitz-.json",
    "value2": "Gesellschafts- und Kulturwissenschaften",
    "fullname": "Prof. Susanne Bennewitz",
    "selecStg": "TD"
  }
};