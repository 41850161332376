import React from "react";
import MDStaffInput from '../MD-Staff-Input';
import StgInfo from '../components/comp-stg-info';


const stginfo = () => {
	return (
		<div>
			<div className="mt-5"></div>
			<h1>
				Studiengang
				{/* <MDStaffInput/> */}
			</h1>
		</div>
	);
};

export default stginfo;
