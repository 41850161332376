import React, { useRef, useState, useEffect } from "react";
import { useField, Formik, Form, Field, FieldArray, ErrorMessage, useFormikContext, } from "formik";

//Importing Functions
import { eng_function, gebauede, titel, standort, selecStgType, selecStg, selecStgSem } from "./functions/const-main";

import { handleFileChange, getTimestampFromFilename, filterJsonFiles, fetchFilesList, filteredFiles, downloadFile, parseTimestampAndFormat, CONFIG } from './functions/const-seafile';
import { imgPortraitPreviewFile, imgLehrgebietPreviewFile } from './functions/const-form-staff';
import { seafile_uploadFile } from './functions/const-seafile-upload'; // Adjust the path as necessary
import SeafileUploadStatus from './components/seafile/seafile-upload-status'; // Adjust the path as necessary

// import { ImportSeafileFile } from './functions/const-seafile-ImportSeafileFile';
import { getUnixTimestamp } from "./functions/func-getunixtimestamp";

import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import QuillEditorStaff from "./components/quill-staff"; // Import the QuillEditor component
import FileInput from "./components/fileinput";

import FormCompText from "./components/formik/form-text";
import FormCompSelect from "./components/formik/form-select";
import FormCompCheckbox from "./components/formik/form-checkbox";
import FormCompButtonAddRemove from "./components/formik/form-button-add";
import FormCompbuttonGeneral from "./components/formik/form-button-gen";

import ElementInfo from "./components/comp-ele-info";
import ResolutionDisplay from "./components/comp-ResolutionDisplay";
import { selecStgMap } from "./const-data/const-selecStgMap";
import SeafileImpFilelist from './components/seafile-imp-filelist';  // Ensure correct imports

import { Button, FormGroup, FormControl, FormLabel, FormSelect, Accordion, Card, Tab, Tabs, Row, Col } from "react-bootstrap";

import axios from 'axios';

import StaffAnleitung from "./components/staff/staff-anleitung";

const StaffInput = () => {
  const fileInputRef = useRef();
  const [courses, setCourses] = useState([]);

  const [imgLehrkraftPreview, setImgLehrkraftPreview] = useState(null);
  const [lehrkraftResolution, setLehrkraftResolution] = useState(null);
  const [imgPortraitPreview, setImgPortraitPreview] = useState(null);
  const [imgLehrgebietPreview, setImgLehrgebietPreview] = useState(null);

  const [portraitResolution, setPortraitResolution] = useState(null);
  const [lehrgebietResolution, setLehrgebietResolution] = useState(null);

  // Start of Logic for Seafile Integration




  const [filesList, setFilesList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');  // State for the search term

  // const boundHandleFileChange = handleFileChange(setFile);
  // const boundHandleUpload = handleUpload(file, setUploadStatus, setFileDetails);

  useEffect(() => {
    fetchFilesList(setFilesList);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter files based on the search term
  const filteredFiles = searchTerm
    ? filesList.filter(file => file.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : filesList;
  //END OF LOGIC FOR SEAFILE

  const [
    setFilePreview,
    values,
    setValues,
    setOptions,
    lehrveranstaltungen,
    setLehrveranstaltungen,
  ] = useState({
    plainTextContent: "",
  });


  useEffect(() => {
    // Process the selecStgMap data
    const processedStaff = Object.entries(selecStgMap).map(([key, value]) => {
      const label = `${value.selecStg}-${value.fullname}-${value.value2}`; // Adjust this format as needed
      return { value: value.value2, label: label };
    });
    setCourses(processedStaff);
  }, []);

  const ImportSeafileFile  = async (filename, setFieldValue, setPreview, fieldName,) => {
    // console.log("Fetching temporary file URL:", CONFIG.finalUrl);
    setFieldValue('img_portrait', '');
    setFieldValue('img_lehrgebiet', '');
    setImgPortraitPreview(imgPortraitPreviewFile);
    setImgLehrgebietPreview(imgLehrgebietPreviewFile);

    try {
      const fetchurl = `${process.env.REACT_APP_SF_proxyUrl}${process.env.REACT_APP_SF_Server}/api/v2.1/via-repo-token/download-link/?path=/Modulhandbuch/Staff/${encodeURIComponent(filename)}`;
      console.log(fetchurl)
      const response = await axios.get(fetchurl, {
        headers: {
          'Authorization': `Token ${process.env.REACT_APP_SF_Imp_Staff_authToken}`,
          'Accept': 'application/json',
          'indent': '4'
        }
      });
      console.log("Response Data Import:", response.data);
      // if (!response.ok) throw new Error('Failed to fetch temporary URL');

      const downloadUrl = response.data;
      console.log('downloadUrl:', downloadUrl);

      const fileResponse = await fetch(downloadUrl);
      console.log("File Response Import:", fileResponse);
      if (!fileResponse.ok) throw new Error('Failed to fetch file content');
      const fileContent = await fileResponse.json();
      console.log("File content received:", fileContent);

      // Process each image field if present
      await Promise.all([
        ['img_portrait', setImgPortraitPreview, setPortraitResolution],
        ['img_lehrgebiet', setImgLehrgebietPreview, setLehrgebietResolution]
      ].map(async ([field, setPreview, setResolution]) => {
        if (fileContent[field]) {
          console.log(`${field} filename on Seafile:`, fileContent[field]);
          await fetchAndSetImage(fileContent[field], setFieldValue, field, setPreview, setResolution);
        }
      }));

      // Update other Formik fields
      Object.keys(fileContent).forEach(key => {
        if (fileContent.hasOwnProperty(key)) {
          setFieldValue(key, fileContent[key]);
        }
      });

    } catch (error) {
      console.error("Error downloading or processing file:", error);
    }
  };

  async function fetchAndSetImage(filename, setFieldValue, fieldName, setPreview, setResolution) {
    const targetUrl = `${CONFIG.server}/api2/repos/059b0483-aa89-4a31-9e60-7439c99785be/file/?p=/Modulhandbuch/Staff/${encodeURIComponent(filename)}`;
    const finalImageUrl = `${process.env.REACT_APP_SF_proxyUrl}${targetUrl}`;

    try {
      // Fetch the temporary download URL for the image
      const imageUrlResponse = await fetch(finalImageUrl, {
        headers: {
          'Authorization': `Token ${CONFIG.authToken}`,
          'Accept': 'application/json'
        }
      });
      if (!imageUrlResponse.ok) throw new Error(`Failed to fetch image from URL: ${finalImageUrl}`);
      const imageUrl = await imageUrlResponse.text().then(text => text.replace(/"/g, ''));

      // Fetch the actual image using the temporary download URL
      const imageResponse = await fetch(imageUrl);
      if (!imageResponse.ok) throw new Error(`Failed to fetch image for ${fieldName}`);
      const imageBlob = await imageResponse.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      // Set the image URL for preview and field value
      setPreview(imageURL); // Assuming setPreview updates the UI
      setFieldValue(fieldName, imageURL); // Update the Formik field

      // Optionally, handle image resolution
      const img = new Image();
      img.onload = () => {
        setResolution({ width: img.width, height: img.height });
      };
      img.src = imageURL;

      // console.log(`${fieldName} set successfully with URL: ${imageURL}`);
    } catch (error) {
      // console.error(`Error fetching or setting image for ${fieldName}:`, error);
    }
  }

  const initialValues = {
    vorname: "Maxime",
    nachname: "Musterfrau",

    titel: "",
    fullname: "",
    eng_function: "",
    email: "",
    room: "",
    gebauede: "",
    standort: "",
    tel: "",
    homepage: "",

    ger_lehrgebiet: "",
    eng_lehrgebiet: "",

    assigndlehrgebiet: [{}],
    filename: "",

    ger_descr_lehrgebiet: "",
    eng_descr_lehrgebiet: "",
    ger_descr_vita: "",
    eng_descr_vita: "",

    sdef_studiengang_sV: [{}],

    eng_role: [{}],
    ger_role: [{}],

    eng_transcr: false,
    transdisziplinaer: false,

    img_portrait: "",
    img_lehrgebiet: "",

    img_portrait_base64: null,
    img_lehrgebiet_base64: null,
  };

  // Step 4: Implement a function to handle file input change and load JSON content
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadInfo, setUploadInfo] = useState([]);


  //Funktion zum Datei importieren
  //Manueller Import mittels Button
  const handleFileInputChange = async (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileContent = await file.text();
        const parsedContent = JSON.parse(fileContent);

        // Update specific fields based on the JSON data
        if (parsedContent.ger_descr) {
          setFieldValue("ger_descr", parsedContent.ger_descr);
        }
        if (parsedContent.ger_modultitel) {
          setFieldValue("ger_modultitel", parsedContent.ger_modultitel);
        }
        if (parsedContent.ger_title) {
          setFieldValue("ger_title", parsedContent.ger_title);
        }

        // Dynamically update other fields based on JSON keys
        for (const key in parsedContent) {
          if (
            parsedContent.hasOwnProperty(key) &&
            initialValues.hasOwnProperty(key)
          ) {
            setFieldValue(key, parsedContent[key]);
          }
        }

        // Handle img_portrait
        if (parsedContent.img_portrait) {
          // Assuming img_portrait is a filename
          setImgPortraitPreview(`./img/${parsedContent.img_portrait}`);
        }

        if (parsedContent.img_lehrgebiet) {
          // Assuming img_portrait is a filename
          setImgLehrgebietPreview(`./img/${parsedContent.img_lehrgebiet}`);
        }

        // Function to load an image and get its resolution
        const loadImageAndGetResolution = (base64String, setResolution) => {
          const img = new Image();
          img.onload = () => {
            if (img) {
              setResolution({ width: img.width, height: img.height });
            }
          };
          img.onerror = () => {
            setResolution(null);
          };
          img.src = base64String;
        };
        // Handle img_portrait_base64
        if (parsedContent.img_portrait_base64) {
          setFieldValue("img_portrait_base64", parsedContent.img_portrait_base64);
          setImgPortraitPreview(parsedContent.img_portrait_base64);
          loadImageAndGetResolution(parsedContent.img_portrait_base64, setPortraitResolution);
        }

        // Handle img_lehrgebiet_base64
        if (parsedContent.img_lehrgebiet_base64) {
          setFieldValue("img_lehrgebiet_base64", parsedContent.img_lehrgebiet_base64);
          setImgLehrgebietPreview(parsedContent.img_lehrgebiet_base64);
          loadImageAndGetResolution(parsedContent.img_lehrgebiet_base64, setLehrgebietResolution);
        }
      } catch (error) {
        console.error("Error loading content from JSON file:", error);
        // Optionally, you can handle errors in a user-friendly way here
      }
    }
  };


  //Beginning of the Export / Submit Function
  //Transmission of the Data to seafile


  const handleSubmit = async (values, setValues, setUploadInfo) => {
    console.log("handleSubmit called with values:", values);
    const getFileExtension = (fileName) => {
      return fileName ? fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2) : "";
    };

    
    const combineFieldArrayEntries = (values) => {
      return values.sdef_studiengang_sV.map((entry) => {
        // Create the initial combined string.
        let combinedString = `${entry.selecStg ? `${entry.selecStg}` : ""}_${entry.selecStgType ? entry.selecStgType.charAt(0).toUpperCase() : ""
          }${entry.selecStgSem ? `.${entry.selecStgSem}` : ""}`;
    
        // Convert the string to lowercase.
        combinedString = combinedString.toLowerCase();
    
        // Replace '.' with an empty space.
        combinedString = combinedString.replace(".", "");
    
        return combinedString;
      });
    };
    const combinedEntries = combineFieldArrayEntries(values);

    // Extract the file objects if they exist and are indeed files
    const imgPortraitFile = values.img_portrait instanceof File ? values.img_portrait : null;
    const imgLehrgebietFile = values.img_lehrgebiet instanceof File ? values.img_lehrgebiet : null;

    // Check if new files were uploaded and set filenames accordingly
    const studiengangInfo = Array.from(new Set(values.sdef_studiengang_sV.map(stg => stg.selecStg))).join("-");
    let filenamestructure = `staff-${values.eng_function.slice(0, 4)}-${studiengangInfo}-${values.vorname}-${values.nachname}`;
    let imgPortraitFilename = imgPortraitFile ? `${filenamestructure}-img-portrait_${getUnixTimestamp()}.${getFileExtension(imgPortraitFile.name)}` : values.img_portrait;
    let imgLehrgebietFilename = imgLehrgebietFile ? `${filenamestructure}-img-lehrgebiet_${getUnixTimestamp()}.${getFileExtension(imgLehrgebietFile.name)}` : values.img_lehrgebiet;

    const jsonData = {
      ...values,
      filename: `${filenamestructure}_${getUnixTimestamp()}`,
      img_portrait: imgPortraitFilename,
      img_lehrgebiet: imgLehrgebietFilename,
      sdef_studiengang: combinedEntries,
    };

    const jsonString = JSON.stringify(jsonData, null, 2);
    const jsonBlob = new Blob([jsonString], { type: "application/json" });

    const jsonLink = document.createElement("a");
    jsonLink.href = URL.createObjectURL(jsonBlob);
    jsonLink.download = `${jsonData.filename}.json`;
    document.body.appendChild(jsonLink);
    jsonLink.click();
    document.body.removeChild(jsonLink);

    if (imgPortraitFile) {
      const imgPortraitLink = document.createElement("a");
      imgPortraitLink.href = URL.createObjectURL(imgPortraitFile);
      imgPortraitLink.download = imgPortraitFilename;
      document.body.appendChild(imgPortraitLink);
      imgPortraitLink.click();
      document.body.removeChild(imgPortraitLink);
    }

    if (imgLehrgebietFile) {
      const imgLehrgebietLink = document.createElement("a");
      imgLehrgebietLink.href = URL.createObjectURL(imgLehrgebietFile);
      imgLehrgebietLink.download = imgLehrgebietFilename;
      document.body.appendChild(imgLehrgebietLink);
      imgLehrgebietLink.click();
      document.body.removeChild(imgLehrgebietLink);
    }



    // Upload files to Seafile
    await seafile_uploadFile(jsonBlob, `${jsonData.filename}.json`, '/Modulhandbuch/Staff/', setUploading, setUploadProgress, setUploadInfo);
    if (imgPortraitFile) {
      await seafile_uploadFile(imgPortraitFile, imgPortraitFilename, '/Modulhandbuch/Staff/', setUploading, setUploadProgress, setUploadInfo);
    }
    if (imgLehrgebietFile) {
      await seafile_uploadFile(imgLehrgebietFile, imgLehrgebietFilename, '/Modulhandbuch/Staff/', setUploading, setUploadProgress, setUploadInfo);
    }

    // Optionally update Formik's state
    if (setValues) {
      setValues(jsonData);
    }
  };

  // const handleSubmit = async (values, setValues) => {
  //   console.log("handleSubmit called with values:", values);
  //   const getFileExtension = (fileName) => {
  //     return fileName ? fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2) : "";
  //   };

  //   function getUnixTimestamp() { return Math.floor(Date.now() / 1000); }

  //   // Extract the file objects if they exist and are indeed files
  //   const imgPortraitFile = values.img_portrait instanceof File ? values.img_portrait : null;
  //   const imgLehrgebietFile = values.img_lehrgebiet instanceof File ? values.img_lehrgebiet : null;

  //   // Check if new files were uploaded and set filenames accordingly
  //   //Dateibenennung
  //   const studiengangInfo = Array.from(new Set(values.sdef_studiengang_sV.map(stg => stg.selecStg))).join("-");

  //   let filenamestructure = `staff-${values.eng_function.slice(0, 4)}-${studiengangInfo}-${values.vorname}-${values.nachname}`;
  //   let imgPortraitFilename = imgPortraitFile ? `${filenamestructure}-img-portrait_${getUnixTimestamp()}.${getFileExtension(imgPortraitFile.name)}` : values.img_portrait;
  //   let imgLehrgebietFilename = imgLehrgebietFile ? `${filenamestructure}-img-lehrgebiet_${getUnixTimestamp()}.${getFileExtension(imgLehrgebietFile.name)}` : values.img_lehrgebiet;

  //   const jsonData = {
  //     ...values,
  //     //Dateibennenung
  //     filename: `${filenamestructure}_${getUnixTimestamp()}`,
  //     img_portrait: imgPortraitFilename,
  //     img_lehrgebiet: imgLehrgebietFilename,
  //   };

  //   const jsonString = JSON.stringify(jsonData, null, 2);
  //   const jsonBlob = new Blob([jsonString], { type: "application/json" });

  //   const jsonLink = document.createElement("a");
  //   jsonLink.href = URL.createObjectURL(jsonBlob);
  //   jsonLink.download = `${jsonData.filename}.json`;
  //   document.body.appendChild(jsonLink);
  //   jsonLink.click();
  //   document.body.removeChild(jsonLink);

  //   if (imgPortraitFile) {
  //     const imgPortraitLink = document.createElement("a");
  //     imgPortraitLink.href = URL.createObjectURL(imgPortraitFile);
  //     imgPortraitLink.download = imgPortraitFilename;
  //     document.body.appendChild(imgPortraitLink);
  //     imgPortraitLink.click();
  //     document.body.removeChild(imgPortraitLink);
  //   }

  //   if (imgLehrgebietFile) {
  //     const imgLehrgebietLink = document.createElement("a");
  //     imgLehrgebietLink.href = URL.createObjectURL(imgLehrgebietFile);
  //     imgLehrgebietLink.download = imgLehrgebietFilename;
  //     document.body.appendChild(imgLehrgebietLink);
  //     imgLehrgebietLink.click();
  //     document.body.removeChild(imgLehrgebietLink);
  //   }

  //   //get url and authtoken from .env
  //   const getUploadLink = async () => {
  //     const url = `${process.env.REACT_APP_SF_proxyUrl}${process.env.REACT_APP_SF_Server}${process.env.REACT_APP_SF_Up_Staff_dirpath}${process.env.REACT_APP_SF_up_Staff_endpath}`;
  //     try {
  //       const response = await axios.get(url, {
  //         headers: {
  //           'Authorization': `Token ${process.env.REACT_APP_SF_Up_Staff_authToken}`,
  //           'Content-Type': 'application/json',
  //           'indent': '4',
  //         }
  //       });
  //       return response.data;
  //     } catch (error) {
  //       return null;  // Return null or handle error appropriately
  //     }
  //   };

  //   const uploadFile = async (file, filename) => {
  //     const uploadUrl_seafile = await getUploadLink();
  //     const uploadUrl = `${process.env.REACT_APP_SF_proxyUrl}${uploadUrl_seafile}`;


  //     if (uploadUrl) {
  //       const formData = new FormData();
  //       formData.append('file', file, filename);
  //       formData.append('parent_dir', '/Modulhandbuch/Staff/');  

  //       setUploading(true);
  //       try {
  //         await axios.post(uploadUrl, formData, {
  //           headers: {
  //             'Authorization': `Token ${process.env.REACT_APP_SF_Up_Staff_authToken}`,
  //             'Content-Type': 'multipart/form-data'
  //           },
  //           onUploadProgress: progressEvent => {
  //             const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //             setUploadProgress(percentCompleted);
  //           }
  //         });
  //         console.log("Upload successful for:", filename);
  //       } catch (error) {
  //         console.error("Error uploading file:", filename, error);
  //       }
  //       setUploading(false);
  //     } else {
  //       console.error("Failed to get a valid upload URL for:", filename);
  //     }
  //   };

  //   // Upload files to Seafile
  //   await uploadFile(jsonBlob, `${jsonData.filename}.json`);
  //   if (imgPortraitFile) {
  //     await uploadFile(imgPortraitFile, imgPortraitFilename);
  //   }
  //   if (imgLehrgebietFile) {
  //     await uploadFile(imgLehrgebietFile, imgLehrgebietFilename);
  //   }

  //   // Optionally update Formik's state
  //   if (setValues) {
  //     setValues(jsonData);
  //   }
  // };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => {
        const ProfFunction = values.eng_function;
        return (
          <Form>
            <div>
              <Row>
                <Col>
                <SeafileImpFilelist
                  searchTerm={searchTerm}
                  handleSearchChange={handleSearchChange}
                  filteredFiles={filteredFiles}
                  downloadFile={downloadFile}
                  parseTimestampAndFormat={parseTimestampAndFormat}
                  ImportSeafileFile={ImportSeafileFile}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  setImgLehrgebietPreview={setImgLehrgebietPreview}
                  setImgPortraitPreview={setImgPortraitPreview}  // Assuming this function is defined in this parent component
                  
                  imgPortraitPreviewFile={imgPortraitPreviewFile}
                  imgLehrgebietPreviewFile={imgLehrgebietPreviewFile}

                  setPortraitResolution={setPortraitResolution}
                  setLehrgebietResolution={setLehrgebietResolution}
                
              />

                </Col>
                <Col md="auto">
                  <FormGroup>
                    <div style={{ marginBottom: "20px" }}>
                      <Button
                        style={{ padding: "20px", fontWeight: "600", fontSize: "20px" }}
                        variant="primary"
                        className="custom-button-blue"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Datei importieren
                      </Button>

                      <input
                        type="file"
                        accept=".json"
                        onChange={(event) =>
                          handleFileInputChange(event, setFieldValue)
                        }
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />

                      <Button
                        style={{ padding: "20px", fontWeight: "600", fontSize: "20px" }}
                        variant="primary"
                        className="custom-button-blue"
                        onClick={() => window.open('https://seafile.rlp.net/library/aafe8ee9-f00d-4c99-b048-78f8f0ff9a56/', '_blank')}
                      >Up Seafile</Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <StaffAnleitung></StaffAnleitung>
              </Col>
            </Row>

            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h2>Kontaktdaten</h2>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <FormCompSelect name="titel" labelText="Titel:" options={titel} />
                      <FormGroup className="formgroup">
                        <FormLabel htmlFor="fullname"></FormLabel>
                        <Field type="text"
                          id="field_s"
                          name="fullname"
                          value={
                            (values.titel ? values.titel + " " : "") +
                            values.vorname + " " +
                            values.nachname
                          }
                          className="form-text"
                        />
                      </FormGroup>
                      <Row>
                        <Col>
                          <FormCompText name="vorname" labelText="Vorname:" />
                        </Col>
                        <Col>
                          <FormCompText name="nachname" labelText="Nachname:" />
                        </Col>
                      </Row>
                      <FormCompText name="email" labelText="Email:" />
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <FormCompSelect name="gebaeude" labelText="Gebäude:" options={gebauede} />
                            </Col>
                            <Col>
                              <FormCompSelect name="standort" labelText="Standort:" options={standort} />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <FormCompText name="room" labelText="Raum:" />
                        </Col>
                        <ElementInfo Infotitle={"Hinweise zur Rauminformation"} Infodescr={""}>
                          <ul>
                            <li>Gebäude Q = Standord Irminenfreihof</li>
                            <li>Gebäude S = Standort Paulusplatz</li>
                            <li>Gebäude R = Standort Paulusplatz <br></br>(es gibt keine eigenständige Standortbezeichnung für das R-Gebäude)</li>
                            <li>Gebäude D = Standort Schneidershof</li>
                            <a href="https://ris.hochschule-trier.de/personalverzeichnis" target="_blank" rel="noopener noreferrer"><li>Bitte überprüfen Sie auch ihre Angaben im RIS System (Personalverzeichnis der Hochschule Trier) auf ihre Aktualität</li></a>
                          </ul>
                        </ElementInfo>
                      </Row>

                      <FormCompText name="tel" labelText="Telefon:" />
                      <ElementInfo
                        Infotitle={"Hinweise zur Telefonnummer"}
                        Infodescr={"In seltenen Fällen haben Sie zwei Telefonnummern, oder auch zwei Büros. Bitte geben Sie diejenigen Daten an, mit denen Sie am besten erreichbar sind."}
                      ></ElementInfo>
                    </Col>

                    <Col>
                      <FormCompText name="homepage" labelText="persönliche Webseite:" />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion flush>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h2>Vita und Rollen</h2>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <FormCompSelect name="eng_function" labelText="Funktion auswählen:" options={eng_function} />
                      <ElementInfo Infotitle={"Hinweise zur Funktion"} Infodescr={""}>
                        je nachdem welche Funktion Sie ausüben, öffnen sich andere Datenfelder<br></br>
                        Sie sind Lehrende*r? Dann vergessen Sie bitte nicht, die Lehrgebietszuordnung
                      </ElementInfo>

                      {ProfFunction === "Employee" && (
                        <FormCompCheckbox name="function_beslehrk" labelText="Ich bin LehrerIn für besondere Aufgaben" />
                      )}
                      <hr></hr>
                      <FormGroup className="formgroup">
                        <FormLabel htmlFor="eng_function">
                          Studiengänge auswählen
                        </FormLabel>

                        <FormLabel htmlFor="eng_function" className="formgroup">
                          <p>In welchen Studiengängen sind Sie tätig? Bitte geben Sie alle Studiengänge an. Die Semesterzahl bezieht sich auf die Regelstudienzeit des Studienganges. (z.B.KD BA 7 Semester, KD MA 3 Semester )
                            <br></br>Eine mehrfach Auswahl ist möglich</p>
                        </FormLabel>

                        <FieldArray name="sdef_studiengang_sV">
                          {({ push, remove }) => (
                            <div>
                              {values.sdef_studiengang_sV.map(
                                (roleItem, index) => (
                                  <Card
                                    key={`sdef_studiengang_sV${index}`}
                                    style={{
                                      padding: "0px!important",
                                      border: "0px solid black",
                                      backgroundColor: "white",
                                      borderRadius: "0px!important",
                                      borderBottom: "5px solid white",
                                    }}
                                  >
                                    <div>
                                      <Row style={{ Left: "0px", position: "relative", margin: "0px", padding: "0px", }}>
                                        <Col xs="auto" style={{ padding: "0px" }}>
                                          <FormCompButtonAddRemove
                                            onAdd={() => push({ roleItem: "" })}
                                            onRemove={() => remove(index)}
                                            addStyle={{ marginRight: "10px" }}
                                            removeStyle={{ marginLeft: "5px" }}
                                          />
                                        </Col>

                                        <Col xs="auto">
                                          <FormCompSelect name={`sdef_studiengang_sV.${index}.selecStgType`} labelText="Type:" options={selecStgType} showLabel={false} />
                                        </Col>

                                        <Col xs="auto">
                                          <FormCompSelect name={`sdef_studiengang_sV.${index}.selecStg`} labelText="Studiengang:" options={selecStg} showLabel={false} />
                                        </Col>

                                        <Col>
                                          {values.sdef_studiengang_sV[index].selecStg !== "TD" && (
                                            <FormCompSelect name={`sdef_studiengang_sV.${index}.selecStgSem`} labelText="Semester:" options={selecStgSem} showLabel={false} />
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              )}
                              <p>TD = Transdisziplinär</p>
                            </div>
                          )}
                        </FieldArray>
                      </FormGroup>
                        <ElementInfo
                          Infotitle={"Hinweise zur Auswahl der Studiengänge"}
                          Infodescr={""}>
                          <div>
                            Bitte geben Sie nur Studiengänge an welche Ihr Lehrgebiet auch im Studienverlaufsplan abbilden. Sollte dies nicht der Fall sein und Sie unterrichten im Kontext der Transdisziplinären Lehre wählen Sie bitte Studiengang TD
                            <hr></hr>Achten Sie bitte auf Vollständigkeit bei den Studiengängen in denen Sie tätig sind.<br></br>
                            Gibt es in Ihrem Fach zum Beispiel zwei BA-Modulhandbücher, zum Beispiel eins für einen 6 Sechsemestrigen und einen 7 Semestrigen,
                            so müssen Sie beide anlegen.</div>
                        </ElementInfo>
                      <Col>
                        <FormGroup className="formgroup">
                          <hr></hr>
                          <FormLabel>
                            <h3>Rollen</h3>
                            <p>
                              Welche Rollen übernehmen Sie an der Hochschule
                              Trier? z.B. Studiengangsleitung, DekanIn etc.
                              Denken Sie hierbei an die Studierenden als
                              RezipientInnen. Welche Rollen sind für diese
                              relevant?
                            </p>
                          </FormLabel>
                          <FieldArray name="ger_role">
                            {({ push, remove }) => (
                              <div>
                                {values.ger_role.map((roleItem, index) => (
                                  <Card
                                    key={`ger_role_${index}`}
                                    style={{
                                      padding: "0px!important",
                                      border: "0px solid black",
                                      backgroundColor: "white",
                                      borderRadius: "0px!important",
                                      borderBottom: "5px solid white",
                                    }}
                                  >
                                    <div>
                                      <Row
                                        style={{
                                          Left: "0px",
                                          position: "relative",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        <Col xs="auto">
                                          <FormCompButtonAddRemove
                                            onAdd={() => push({ ger_role: "" })}
                                            onRemove={() => remove(index)}
                                            addStyle={{ marginRight: "10px" }}
                                            removeStyle={{ marginLeft: "5px" }}
                                          />
                                        </Col>

                                        <Col>
                                          <Field name={`ger_role.${index}.ger_role`} className="form-text" />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </FormGroup>

                        {values.eng_transcr && (
                          <FormGroup className="formgroup">
                            <FormLabel>Rollen (englisch)</FormLabel>
                              <FieldArray name="eng_role">
                                {({ push, remove }) => (
                                  <div>
                                    {values.eng_role.map((roleItem, index) => (
                                      <Card
                                        key={`eng_role_${index}`}
                                        style={{
                                          padding: "0px!important",
                                          border: "0px solid black",
                                          backgroundColor: "white",
                                          borderRadius: "0px!important",
                                          borderBottom: "5px solid white",
                                        }}
                                      >
                                        <div>
                                          <Row
                                            style={{
                                              Left: "0px",
                                              position: "relative",
                                              margin: "0px",
                                              padding: "0px",
                                            }}
                                          >
                                            <Col xs="auto">
                                              <FormCompButtonAddRemove
                                                onAdd={() => push({ eng_role: "" })}
                                                onRemove={() => remove(index)}
                                                addStyle={{ marginRight: "10px" }}
                                                removeStyle={{}}
                                              />
                                            </Col>

                                            <Col>
                                              <Field
                                                name={`eng_role.${index}.eng_role`}
                                                className="form-text"
                                              />
                                            </Col>

                                          </Row>
                                        </div>
                                      </Card>
                                    ))}
                                  </div>
                                )}
                              </FieldArray>
                          </FormGroup>
                        )}

                        <ElementInfo
                          Infotitle={"Hinweise zu den Rollen"}
                          Infodescr={""}>
                          Bitte beachten Sie dass bei bestimmten Titeln die Bezeichnung nach bestimmten Vorgaben erfolgen soll.
                          Diese befinden sich zurzeit noch in Bearbeitung und werden in der nächsten Korrekturschleife veröffentlicht.
                          Die Vorgaben beziehen sich vor allem auf die englischen Bezeichnungen.
                          <ul>
                            <li>Geben Sie bei den Rollenbeschreibungen möglichst neutrale Bezeichnungen an</li>
                            <ul>
                              <li>statt Professor für lieber Professur</li>
                              <li>statt Auslandskoordinatorin lieber Auslandskoordination</li>
                            </ul>
                          </ul>
                        </ElementInfo>

                        <hr></hr>

                        {
                          ProfFunction === "Employee" && (
                            <>
                              <div>
                                <FormGroup className="formgroup">
                                  <FormLabel>
                                    Lehrgebiet auswählen
                                  </FormLabel><br></br>
                                  Sind Sie einem bestimmten Lehrgebiet zugeordnet oder unterrichten Sie in mehreren Lehrgebieten? Bitte wählen Sie hier das entsprechende Lehrgebiet aus.
                                </FormGroup>
                              </div>

                              <FieldArray name="assigndlehrgebiet">
                                {({ push, remove }) => (
                                  <div>
                                    {values.assigndlehrgebiet.map((item, index) => (
                                      <Card
                                        key={`lehrgebiet_${index}`}
                                        style={{
                                          padding: "0px!important",
                                          border: "0px solid black",
                                          backgroundColor: "white",
                                          borderRadius: "0px!important",
                                          borderBottom: "5px solid white",
                                        }}
                                      >
                                        <Row className="d-flex align-items-center" style={{ margin: "0px", padding: "0px" }}>
                                          <Col xs={2} md={2}>
                                            <div style={{ marginTop: "auto" }}>
                                              {index > 0 && (
                                                <Button
                                                  variant="danger"
                                                  onClick={() => remove(index)}
                                                  className="btn-remove"
                                                  style={{ marginRight: "10px" }}
                                                >
                                                  -
                                                </Button>
                                              )}
                                            </div>
                                          </Col>
                                          <Col xs={10} md={10}>
                                            {/* <FormSelect name={`assigndlehrgebiet[${index}].ger_lehrgebiet`} labelText="Lehrgebiet auswählen:" options={courses} showLabel={true}/> */}

                                            <Field
                                              name={`assigndlehrgebiet[${index}].ger_lehrgebiet`}
                                              as="select"
                                              id={`selecStg_${index}`}
                                              className="form-selec"
                                            >
                                              <option value="" >
                                                Lehrgebiet auswählen
                                              </option>
                                              {courses.map((course) => (
                                                <option key={course.value} value={course.value}>
                                                  {course.label}
                                                </option>
                                              ))}
                                            </Field>
                                          </Col>
                                        </Row>
                                      </Card>
                                    ))}
                                    <Button
                                      variant="primary"
                                      onClick={() => push({ ger_lehrgebiet: "" })}
                                      className="btn-add"
                                      style={{ marginTop: "10px" }}
                                    >
                                      +
                                    </Button>
                                  </div>
                                )}
                              </FieldArray>

                            </>
                          )
                        }


                      </Col>
                    </Col>

                    <Col>
                      <FormGroup className="formgroup">
                        <FormLabel>Persönliche Beschreibung</FormLabel>
                        <FormCompCheckbox name="eng_transcr" labelText="Englische Übersetzung hinterlegen" />
                        <QuillEditorStaff
                          maxChars={1500}
                          value={values.ger_descr_vita} // Pass the correct field from Formik values
                          onChange={(html) => {
                            setFieldValue("ger_descr_vita", html); // Correctly update the field in Formik
                          }}
                          fieldname="ger_descr_vita" // Correct the field name (remove the colon)
                        />
                      </FormGroup>

                      {values.eng_transcr && (
                        <FormGroup className="formgroup">
                          <FormLabel>
                            Persönliche Beschreibung in Englisch
                          </FormLabel>
                          <QuillEditorStaff
                            maxChars={1500}
                            value={values.eng_descr_vita} // Pass the correct field from Formik values
                            onChange={(html) => {
                              setFieldValue("eng_descr_vita", html); // Correctly update the field in Formik
                            }}
                            fieldname="eng_descr_vita" // Correct the field name (remove the colon)
                          />
                        </FormGroup>
                      )}

                      <ElementInfo Infotitle={"Hinweise zum Text"} Infodescr={""}>
                        <ul>
                          <li>Verfassen Sie einen Fliesstext, versuchen Sie sich in die Rezipienten (die Studierenden) hineinzuversetzen.
                            Welche Informationen zum Lehrgebiet ist relevant und wichtig. Welche Informationen dienen dazu das Lehrgebiet und deren Kursinhalte besser einzuordnen.
                          </li>
                          <li>
                            Beachten Sie die Zeichenbegrenzung in den freien Textfelder. Ist diese erreicht, springt die Eingabe zurück an den Anfang.</li>
                        </ul>
                      </ElementInfo>

                      <Row>
                        <Col>
                          <div
                            className="helper"
                            style={{
                              display: "flex", // Enable Flexbox
                              alignItems: "center", // Vertically center content
                              justifyContent: "left", // Horizontally center content (optional, based on your needs)
                              minWidth: "100%",
                              color: "black",
                              backgroundColor: "#E0E0E0",
                              minHeight: "40px",
                              paddingLeft: "20px",
                            }}
                          >
                            Hinterlegen Sie hier ihr Portrait
                          </div>
                        </Col>
                        <Col xs="auto">
                          <FileInput
                            name="img_portrait"
                            setImageResolution={setPortraitResolution}
                            setFilePreview={setImgPortraitPreview}
                            setBase64={(base64String) =>
                              setFieldValue(
                                "img_portrait_base64",
                                base64String
                              )
                            }
                            enableBase64={true} // Set this to true to enable Base64 conversion
                          />
                        </Col>
                      </Row>
                      
                      <Row>
                        <div>
                          {/* Preview container for img_lehrgebiet */}

                          <div>
                            <ResolutionDisplay resolution={portraitResolution} minWidth={1920} />
                            <img
                              src={imgPortraitPreview || imgPortraitPreviewFile}
                              alt="Portrait Vorschau"
                              style={{
                                width: "100%",
                                minHeight: "100px",
                                maxWidth: "100%",
                                display: "block",
                                padding: "0px",
                                marginTop: "20px",
                              }}
                            />
                          </div>
                          <p>
                            Portrait Bild Vorschau:<br></br>
                          </p>
                        </div>
                      </Row>
                      <Row></Row>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {ProfFunction === "Professor" && (
              <Accordion flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h2>Angaben Lehrgebiet</h2>
                  </Accordion.Header>
                  <Accordion.Body style={{ minHeight: "00px" }}>
                    <Row>
                      <Col>
                        <div>
                          <Row>
                            <Col>
                              <div
                                className="helper"
                                style={{
                                  display: "flex", // Enable Flexbox
                                  alignItems: "center", // Vertically center content
                                  justifyContent: "left", // Horizontally center content (optional, based on your needs)
                                  minWidth: "100%",
                                  color: "black",
                                  backgroundColor: "#E0E0E0",
                                  minHeight: "40px",
                                  paddingLeft: "20px",
                                }}
                              >
                                Hinterlegen Sie hier ein Bild für das
                                Lehrgebiet
                              </div>
                            </Col>
                            <Col xs="auto">
                              <FileInput
                                // label="Bild Lehrgebiet auswählen"

                                name="img_lehrgebiet"
                                setFilePreview={setImgLehrgebietPreview}
                                setImageResolution={setLehrgebietResolution}
                                setBase64={(base64String) =>
                                  setFieldValue(
                                    "img_lehrgebiet_base64",
                                    base64String
                                  )
                                }
                                enableBase64={true} // Set this to true to enable Base64 conversion
                              />
                            </Col>
                          </Row>
                          <Row>
                            <div>
                              {/* Preview container for img_lehrgebiet */}

                              <div>
                                <ResolutionDisplay resolution={lehrgebietResolution} minWidth={1920} />
                                <img
                                  src={
                                    imgLehrgebietPreview ||
                                    imgLehrgebietPreviewFile
                                  }
                                  alt="Lehrgebiet Vorschau"
                                  style={{
                                    width: "100%",
                                    minHeight: "100px",
                                    maxWidth: "100%",
                                    display: "block",
                                    padding: "0px",
                                    marginTop: "20px",
                                  }}
                                />
                              </div>
                              <p>
                                Lehrgebiet Bild Vorschau:<br></br>
                              </p>
                            </div>
                          </Row>
                        </div>
                      </Col>

                      <Col>
                        <FormCompText name="ger_lehrgebiet" labelText="Titel Ihres Lehrgebietes:" />

                        {values.eng_transcr && (
                          <FormCompText name="eng_lehrgebiet" labelText="Titel Ihres Lehrgebietes (english):" />
                        )}

                        <div className="btn-descr-enc">
                          <FormLabel
                            htmlFor="transdisziplinaer"
                            className="btn-dscr-eng-enab"
                          ></FormLabel>
                          <label className="btn-checkb-checkbox">
                            <Field
                              type="checkbox"
                              name="transdisziplinaer"
                              className="hidden-checkbox"
                              id="transdisziplinaer" // Added id for association with the label
                            />
                            <div className="btn-checkb">
                              Bei meinem Lehrgebiet handelt es sich um ein
                              transdisziplinäres Lehrgebiet
                            </div>
                          </label>
                        </div>

                        <FormGroup className="formgroup">
                          <FormLabel>Beschreibung des Lehrgebietes</FormLabel>
                          <FormCompCheckbox name="eng_transcr" labelText="Englische Übersetzung hinterlegen" />
                          <QuillEditorStaff
                            maxChars={1500}
                            value={values.ger_descr_lehrgebiet} // Pass the correct field from Formik values
                            onChange={(html) => { setFieldValue("ger_descr_lehrgebiet", html); }}
                            fieldname="ger_descr_lehrgebiet" // Correct the field name (remove the colon)
                          />
                        </FormGroup>



                        {values.eng_transcr && (
                          <FormGroup>
                            <FormLabel>
                              Englische Beschreibung des Lehrgebietes
                            </FormLabel>
                            <QuillEditorStaff
                              maxChars={1500}
                              value={values.eng_descr_lehrgebiet} // Pass the correct field from Formik values
                              onChange={(html) => {
                                setFieldValue("eng_descr_lehrgebiet", html); // Correctly update the field in Formik
                              }}
                              fieldname="eng_descr_lehrgebiet" // Correct the field name (remove the colon)
                            />
                          </FormGroup>
                        )}

                        <ElementInfo
                          Infotitle={"Hinweise zum Text"}
                          Infodescr={
                            "Verfassen Sie einen Fliesstext, versuchen Sie sich in die Rezipienten (die Studierenden) hineinzuversetzen. Welche Infomrationen zum Lehrgebiet ist relevant und wichtig. Welche Informationen dienen dazu das Lehrgebiet und deren Kursinhalte besser einzuordnen."
                          }
                        ></ElementInfo>

                        <h3></h3>
                        <p></p>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}



            {values.function_beslehrk && (
              <Accordion flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h2>Angaben zu Ihrem Bereich als Lehrkraft für besondere Aufgaben</h2>
                  </Accordion.Header>
                  <Accordion.Body style={{ minHeight: "00px" }}>
                    <Row>
                      <Col>
                        <div>
                          <Row>
                            <Col>
                              <div
                                className="helper"
                                style={{
                                  display: "flex", // Enable Flexbox
                                  alignItems: "center", // Vertically center content
                                  justifyContent: "left", // Horizontally center content (optional, based on your needs)
                                  minWidth: "100%",
                                  color: "black",

                                  backgroundColor: "#E0E0E0",
                                  minHeight: "40px",
                                  paddingLeft: "20px",
                                }}
                              >
                                Hinterlegen Sie hier ein Bild für das
                                ihren Bereich als besondere Lehrkraft
                              </div>
                            </Col>
                            <Col xs="auto">
                              <FileInput
                                // label="Bild Lehrgebiet auswählen"

                                name="img_lehrkraft_ber"
                                setFilePreview={setImgLehrkraftPreview}
                                setImageResolution={setLehrgebietResolution}
                                setBase64={(base64String) =>
                                  setFieldValue(
                                    "img_lehrkraft_ber",
                                    base64String
                                  )
                                }
                                enableBase64={true} // Set this to true to enable Base64 conversion
                              />
                            </Col>
                          </Row>
                          <Row>
                            <div>
                              {/* Preview container for img_lehrgebiet */}

                              <div>
                                <ResolutionDisplay resolution={lehrgebietResolution} minWidth={1920} />
                                <img
                                  src={
                                    imgLehrgebietPreview ||
                                    imgLehrgebietPreviewFile
                                  }
                                  alt="Lehrgebiet Vorschau"
                                  style={{
                                    width: "100%",
                                    minHeight: "100px",
                                    maxWidth: "100%",
                                    display: "block",
                                    padding: "0px",
                                    marginTop: "20px",
                                  }}
                                />
                              </div>
                              <p>
                                Lehrgebiet Bild Vorschau:<br></br>
                              </p>
                            </div>
                          </Row>
                        </div>
                      </Col>

                      <Col>
                        <FormGroup className="formgroup">
                          <FormLabel htmlFor="homepage">
                            Bezeichnung Ihres Bereichs:
                          </FormLabel>
                          <Field
                            type="text"
                            id="field_s"
                            name="ger_lehrgebiet"
                            className="form-text"
                          />
                        </FormGroup>

                        {values.eng_transcr && (
                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="homepage">
                              Titel Ihres Lehrgebietes (english):
                            </FormLabel>
                            <Field
                              type="text"
                              id="field_s"
                              name="eng_lehrgebiet"
                              className="form-text"
                            />
                          </FormGroup>
                        )}

                        <FormCompCheckbox name="transdisziplinaer" labelText="Bei meinem Lehrgebiet handelt es sich um ein transdisziplinäres Lehrgebiet" />

                        <FormGroup className="formgroup">
                          <FormLabel>Beschreibung des Lehrgebietes</FormLabel>
                          <FormCompCheckbox name="eng_transcr" labelText="Englische Übersetzung hinterlegen" />
                          <QuillEditorStaff
                            maxChars={1500}
                            value={values.ger_descr_lehrgebiet} // Pass the correct field from Formik values
                            onChange={(html) => {
                              setFieldValue("ger_descr_lehrgebiet", html); // Correctly update the field in Formik
                            }}
                            fieldname="ger_descr_lehrgebiet" // Correct the field name (remove the colon)
                          />
                        </FormGroup>



                        {values.eng_transcr && (
                          <FormGroup>
                            <FormLabel>
                              Englische Beschreibung des Lehrgebietes
                            </FormLabel>
                            <QuillEditorStaff
                              maxChars={1500}
                              value={values.eng_descr_lehrgebiet} // Pass the correct field from Formik values
                              onChange={(html) => {
                                setFieldValue("eng_descr_lehrgebiet", html); // Correctly update the field in Formik
                              }}
                              fieldname="eng_descr_lehrgebiet" // Correct the field name (remove the colon)
                            />
                          </FormGroup>
                        )}

                        <ElementInfo
                          Infotitle={"Hinweise zum Text"}
                          Infodescr={
                            "Verfassen Sie einen Fliesstext, versuchen Sie sich in die Rezipienten (die Studierenden) hineinzuversetzen. Welche Infomrationen zum Lehrgebiet ist relevant und wichtig. Welche Informationen dienen dazu das Lehrgebiet und deren Kursinhalte besser einzuordnen."
                          }
                        ></ElementInfo>

                        <h3></h3>
                        <p></p>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
            <FormGroup>
              <div style={{ marginBottom: "50px" }}>
                <Button
                  style={{ padding: "20px", fontWeight: "600", fontSize: "20px", backgroundColor: "#B6CFD0!important" }}
                  variant="primary"
                  type="button"
                  className="custom-button"
                  onClick={() => handleSubmit(values, setValues, setUploadInfo)}>
                    Datei lokal speichern
                </Button>
              </div>
            </FormGroup>
            <SeafileUploadStatus uploading={uploading} uploadProgress={uploadProgress} uploadInfo={uploadInfo} />

          </Form>
        );
      }}
    </Formik>
  );
};
export default StaffInput;