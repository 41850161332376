import React, { useState } from 'react';
import { Accordion, Button, Row, Col, FormControl } from 'react-bootstrap';
// import { ImportSeafileFile } from '../functions/const-seafile-ImportSeafileFile';
import { ImportSeafileFile } from '../functions/const-seafile';

const SeafileImpFilelist = ({
  searchTerm, handleSearchChange, filteredFiles, parseTimestampAndFormat,
  setFieldValue, setImgPortraitPreview, setImgLehrgebietPreview, ImportSeafileFile,
  setPortraitResolution, setLehrgebietResolution, initialValues
}) => {


    
    const [filter, setFilter] = useState("");
    const [activeKey, setActiveKey] = useState('import');  // Initially 'import' is open

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    const getFilteredFiles = () => {
        return filteredFiles.filter(file => {
            return (filter === "" || file.name.includes(filter)) && file.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
    };

    const handleImportClick = async (file) => {
        await ImportSeafileFile(file.name, setFieldValue, setImgPortraitPreview, setImgLehrgebietPreview, setPortraitResolution, setLehrgebietResolution, initialValues);
        setActiveKey(null);  // Close the 'import' accordion
    };

    const toggleAccordion = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    
    // Filter function to select only JSON files
    const filterJsonFiles = (files) => {
        if (Array.isArray(files)) {
            return files.filter(file => file.type === 'file' && file.name.endsWith('.json'));
        } else {
            console.error('Provided data is not an array:', files);
            return []; // Return an empty array to avoid further errors
        }
    };

    // asdas
    // Generate a filelist based on the selected Repository/Directory on Seafile
    // add new const for api based on component
    // const fetchFilesList = async (setFilesList) => {
    //     const url = `${CONFIG.proxyUrl}/${CONFIGSeafileDirApi.server}/api/v2.1/via-repo-token/dir/?path=/Modulhandbuch/Staff/`
    //     console.log("Request URL:", url); // Debugging output

    //     try {
    //         const response = await axios.get(url, {
    //             headers: {
    //                 'Authorization': `Token ${CONFIGSeafileDirApi.authToken}`, //use token based on component
    //                 'Accept': 'application/json; indent=4'
    //             }
    //         });
    //         console.log("Response Data:", response.data); // Detailed debugging output

    //         if (response.data && response.data.dirent_list && Array.isArray(response.data.dirent_list)) {
    //             const jsonFiles = filterJsonFiles(response.data.dirent_list);
    //             setFilesList(jsonFiles);
    //         } else {
    //             console.error('Unexpected data format or no data:', response.data);
    //             setFilesList([]); // Handle gracefully by setting an empty list
    //         }
    //     } catch (error) {
    //         console.error('Error fetching files list:', error);
    //     }}


   


    return (
        <Accordion flush className="info" activeKey={activeKey} onSelect={toggleAccordion}>
            <Accordion.Item eventKey="import">
                <Accordion.Header><h4>Dateien importieren</h4></Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col>
                            <h3>Files in Directory:</h3>
                            <FormControl
                                type="text"
                                placeholder="Nach Vorlagedateien suchen"
                                className="mr-sm-2"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <div className="mb-2" style={{marginTop:"20px", marginBottom:"20px", paddingBottom:"20px",borderBottom:"2px solid black"}}>
                                <Button variant="secondary" onClick={() => handleFilterChange("")}>All</Button>
                                <Button variant="secondary" onClick={() => handleFilterChange("AR")}>AR</Button>
                                <Button variant="secondary" onClick={() => handleFilterChange("ID")}>ID</Button>
                                <Button variant="secondary" onClick={() => handleFilterChange("KD")}>KD</Button>
                                <Button variant="secondary" onClick={() => handleFilterChange("MD")}>MD</Button>
                                <Button variant="secondary" onClick={() => handleFilterChange("IA")}>IA</Button>
                                <Button variant="secondary" onClick={() => handleFilterChange("ES")}>ES</Button>
                            </div>
                            {getFilteredFiles().map((file, index) => (
                                <Row key={index} style={{ minWidth: "100%", marginBottom: "5px", marginTop:"5px", borderBottom: "1px solid black", paddingBottom:"5px"}}>
                                    <Col className="col-1">
                                        <Button onClick={() => handleImportClick(file)}>Import</Button>
                                    </Col>
                                    <Col className="col">{file.name}</Col>
                                    <Col className="col" style={{ fontSize:"1rem" }}>{parseTimestampAndFormat(file.name)}</Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default SeafileImpFilelist;
