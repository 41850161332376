// src/config/llmConfig.js
const LLM_CONFIG = {
    API_URL: 'https://api.groq.com/openai/v1/chat/completions',
    API_KEY: process.env.REACT_APP_GROQ_APIKEY,
    MODEL: 'gemma2-9b-it', // oder dein gewünschtes Modell
    TEMPERATURE: 0.7,

    // API leerer String
    // Model: Mistral-Small-24B-Instruct-2501 
  };
  
  export default LLM_CONFIG;
  