import React from "react";
import MdInput from "../MD-Input";
import Exhiblist from "../components/exhib-list";

const exhib = () => {
	return (
		<div>
			<div className="mt-5"></div>
			<h1>
				Ausstellung Übersicht
				<Exhiblist></Exhiblist>
				{/* <MdInput></MdInput> */}
			</h1>
		</div>
	);
};

export default exhib;
