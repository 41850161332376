// Feedback.js
import React from 'react';
import { Accordion } from 'react-bootstrap';
import ContDescr from "../../basic-elements/comp-cont-descr";
import QuillEditorStaffv2 from "../../quill-staff_v2";
import Icon_folder from "../../icons/icon-folder";
import CatTitle from "../../basic-elements/comp-cattitle";

const AccThReflexion = ({ displayStg, values, setFieldValue, handleInputChange }) => {
    const {
        projektart = {},
        persondata = [],
        assignedLehrende = [],
        semestertype = {},
        jahr = '',
        selecStgType = '',
        selecStg = {},
        ger_project_tags = [],
        eng_project_tags = [],
        ger_descr_projekt_short = '',
        eng_descr_projekt_short = '',
        ger_descr_projekt_long = '',
        eng_descr_projekt_long = ''
    } = values;

    if (
        !selecStg?.value || // 🛑 kein Wert gesetzt
        (Array.isArray(displayStg) && !displayStg.includes(selecStg.value)) // ❌ nicht erlaubt
      ) {
        
        return null;
      }

  return (
    <>
    <CatTitle IconComponent={Icon_folder} titleText="Theoretische Reflexion" />
    
    <Accordion>
      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <h2>Hinterlasse uns hier dein Feedback</h2>
        </Accordion.Header>
        <Accordion.Body>
          <ContDescr>
            Hinterlasse uns hier eine Rückmeldung zum Abgabepaket. Wir versuchen
            das Programm konstant zu verbessern.
          </ContDescr>
          <QuillEditorStaffv2
            maxChars={600}
            value={values.ger_feedback_test}
            onChange={(html) => {
              setFieldValue("ger_feedback_test", html);
              handleInputChange("ger_feedback_test", html);
            }}
            fieldname="ger_feedback_test"
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </>
  );
};

export default AccThReflexion;
