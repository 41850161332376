import React from 'react';
import { FastField } from 'formik';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomTooltip from '../basic-elements/btrap-tooltip';



const FormText = ({ name, labelText, onChange, tooltip, showLabel = true }) => {
  return (
    <FastField name={name}>
      {({ field, meta }) => {
        const additionalClass = meta.touched && field.value ? "form-text-selected" : "";
        const additionalClass_label = meta.touched && field.value ? "formgroup-selected" : "";

        return (
          <FormGroup className={`formgroup ${additionalClass_label}`} style={{ margin: 0, padding: 0 }}>
            {showLabel && <FormLabel htmlFor={name}>{labelText}</FormLabel>}
            <CustomTooltip message={tooltip}>
            <input
              type="text"
              {...field}
              className={`form-text ${additionalClass}`}
              style={{ width: "100%", }}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
            />
            </CustomTooltip>
          </FormGroup>
          
        );
      }}
    </FastField>
  );
};

export default FormText;
