import axios from 'axios';


//make sure that the token and folder is sent to the function directly from the main component
//the function should be universal

export const seafile_uploadFile = async (file, filename, uploadPath, setUploading, setUploadProgress, setUploadInfo) => {
  const getUploadLink = async () => {
    const url = `${process.env.REACT_APP_SF_proxyUrl}${process.env.REACT_APP_SF_Server}${process.env.REACT_APP_SF_Up_Staff_dirpath}${process.env.REACT_APP_SF_up_Staff_endpath}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Token ${process.env.REACT_APP_SF_Up_Staff_authToken}`,
          'Content-Type': 'application/json',
          'indent': '4',
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error getting upload link:", error);
      return null;  // Return null or handle error appropriately
    }
  };

  const uploadUrl_seafile = await getUploadLink();
  const uploadUrl = `${process.env.REACT_APP_SF_proxyUrl}${uploadUrl_seafile}`;

  if (uploadUrl) {
    const formData = new FormData();
    formData.append('file', file, filename);
    formData.append('parent_dir', uploadPath);

    setUploading(true);
    try {
      await axios.post(uploadUrl, formData, {
        headers: {
          'Authorization': `Token ${process.env.REACT_APP_SF_Up_Staff_authToken}`,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      console.log("Upload successful for:", filename);
      if (typeof setUploadInfo === 'function') {
        setUploadInfo(prev => [...prev, { name: filename, size: file.size }]);
      } else {
        console.error("setUploadInfo is not a function");
      }
    } catch (error) {
      console.error("Error uploading file:", filename, error);
    }
    setUploading(false);
  } else {
    console.error("Failed to get a valid upload URL for:", filename);
  }
};
