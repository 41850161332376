import React, { useEffect, useState } from 'react';
import { Button, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { useFileHandler } from '../../functions/formik/useFileHandler';
import { useFormikContext } from 'formik';
import useUpdateFilenames from '../../functions/hooks/react-useupdateFilenames';
import FormCompButtonAddRemove from "../formik/form-button-add";

const FormFileSelectPDF = ({
  selecStg,   // current study program object
  displayStg, // Array of allowed values like ['KD', 'MD']
  amount_input_field,
  label,
  name,
  dispatch,
  pdfState,
  desiredWidth,
  desiredHeight,
  cropmark,
  unit,
  onChange,  
  imgnaming,
  countarrayState,
  countarrayDispatch,
  enableAddButton
}) => {
    
  const { values, setFieldValue } = useFormikContext();
  const [inputCount, setInputCount] = useState(amount_input_field);

  const { handleFileChange, fileInfo, errorMessage } = useFileHandler(name, cropmark, unit, desiredWidth, desiredHeight, dispatch);
  
  useUpdateFilenames({ name, inputCount, state: pdfState, setFieldValue, imgnaming });

  // Sync the input count from `countarrayState` to Formik and local state
  useEffect(() => {
    const key = `${name}_inputCount`;

    const newCount = countarrayState?.[key];
    // console.log(`[FormFileSelectIMG] CountArrayState newCount:`, newCount);

    if (newCount !== undefined) {
      setFieldValue(`${name}_inputCount`, newCount);
      setInputCount(newCount);
      // console.log(`[FormFileSelectIMG] new count undefined`, newCount);
    }
  }, [countarrayState, name, setFieldValue]);

  // console.log("[FormFileSelectPDF] Current countarrayState:", countarrayState);

  // Dispatch the current input count to `countarrayReducer` and sync it with Formik
  useEffect(() => {
    const key = `${name}_inputCount`;
    console.log(`[FormFileSelectPDF] Dispatching inputCount for "${key}":`, inputCount);
    dispatch({
      type: 'SET_MAX_INPUT_COUNT', // Dispatch to update countarrayReducer
      fieldName: name, // Base name (e.g., img_Web or pdf_plakat)
      count: inputCount, // Updated input count
    });
    setFieldValue(key, inputCount); // Sync with Formik
  }, [inputCount, dispatch, name, setFieldValue]);

  // Initialize the input count with the default value if not already set in `countarrayState`
  useEffect(() => {
    const key = `${name}_inputCount`;
    if (!countarrayState?.[key]) {
      // console.log(`[FormFileSelectPDF] Initializing "${key}" with default value:`, amount_input_field);
      setFieldValue(key, amount_input_field); // Default to initial value
      setInputCount(amount_input_field);
    }
  }, [countarrayState, name, setFieldValue, amount_input_field]);

  const addInputField = () => {
    setInputCount((currentCount) => currentCount + 1);
  };

  const removeInputField = () => {
    setInputCount((currentCount) => Math.max(currentCount - 1, 1));
  };

  const handleFileInputChangePDF = (event, fieldName) => {
    const file = event.currentTarget.files[0];
    if (file) {
      console.log(`[FormFileSelectPDF] handleFileInputChangePDF - File Selected: ${file.name}`);
      console.log(`[FormFileSelectPDF] desiredHeight type: ${typeof desiredHeight}`);
      console.log(`[FormFileSelectPDF] desiredHeight value:`, desiredHeight);

      handleFileChange(event);
      const pdfUrl = URL.createObjectURL(file);
  
      // Dispatch URL and selection status
      dispatch({
        type: 'SET_PDF_URL',
        fieldName,
        pdfUrl,
      });
  
      const newFilename = `${imgnaming}_${fieldName}.pdf`;
      setFieldValue(fieldName, file); // Update Formik with file
      setFieldValue(`${fieldName}_filename`, newFilename); // Update Formik with filename
      setFieldValue(`${name}_inputCount`, inputCount); // Sync inputCount with Formik
  
      if (onChange) {
        onChange({ filename: newFilename });
      }
    }
  };

  const inputFields = () => {
    let fields = [];
    for (let i = 1; i <= inputCount; i++) {
      const currentName = `${name}_${i}`;
      const pdfData = pdfState[currentName] || {};
      const pdfUrl = pdfData.url || "";
      const comp_filename_pdf = `${imgnaming}_${currentName}.pdf`;

      fields.push(
        <Row key={currentName} style={{ marginBottom: '0px' }}>
          <Col md='8'>
            <div style={{ minWidth: '100%', backgroundColor: '#ededed', padding: '10px', marginBottom:'10px', border: "1px solid grey" }}>
              <FormGroup className="formgroup">
                {label && (
                  <FormLabel htmlFor={currentName}>
                    <div>
                      <div className="btn_fileselect_inf btn_fileselect_info_first">{`${label} ${i}`}</div>
                      <div className='btn_fileselect_inf' style={{ fontWeight: 'normal' }}>Format: {desiredWidth}{unit} x {desiredHeight}{unit}</div>
                      <div className='btn_fileselect_inf' style={{ fontWeight: 'normal' }}>
                        Dateiname: {pdfData.isFileSelected ? values[`${currentName}_filename`] : ""}
                      </div>
                    </div>
                  </FormLabel>
                )}
                <input
                  id={currentName}
                  name={currentName}
                  type="file"
                  accept="application/pdf"
                  onChange={(event) => handleFileInputChangePDF(event, currentName)}
                  style={{ display: "none" }}
                />
                <Button as="label" htmlFor={currentName} className='btn_fileselect_select_pdf'>
                  Datei auswählen
                </Button>

                <Row style={{ display: 'none' }}>

                  <Col md="auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h4>Dateibenennung</h4>
                  </Col>
                  <Col>
                    <input
                      type="text"
                      name={`${currentName}_filename`}
                      className="form-text-info"
                      value={pdfData.isFileSelected ? values[`${currentName}_filename`] : ""}
                      readOnly
                      style={{ userSelect: "none", cursor: "default", display:'none' }}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
            {errorMessage && (
              <div className="warn-no-crop">
                {errorMessage}
              </div>
            )}
          </Col>
          <Col>
            {pdfUrl ? (
              <iframe src={pdfUrl} style={{ width: '100%', height: '100%', minHeight: '800px', marginTop: '20px' }} title="PDF Preview"></iframe>
            ) : (
              <div className='form_file_pdf_noPDF'>Noch kein PDF ausgewählt</div>
            )}
          </Col>
        </Row>
      );
    }
    return fields;
  };

  if (
    !selecStg?.value || // 🛑 kein Wert gesetzt
    (Array.isArray(displayStg) && !displayStg.includes(selecStg.value)) // ❌ nicht erlaubt
  ) {
    console.log(`[FormFileSelectPDF] Skipping "${name}" – selecStg is "${selecStg?.value}"`);
    return null;
  }

  return (
    <>
      <div>
        {inputFields()}
        {enableAddButton && (
          <FormCompButtonAddRemove
            onAdd={addInputField}
            onRemove={removeInputField}
            addStyle={{ marginRight: "10px", marginTop:'20px', marginBottom:'20px' }}
            removeStyle={{ marginLeft: "5px" }}
          />
        )}
      </div>
      <input
        type="text"
        name={`${name}_inputCount`}
        value={`${inputCount}`}
        readOnly
        style={{ display: "none" }}
      />
      <hr></hr>
    </>
  );
};

export default FormFileSelectPDF;
