import React from 'react';

const SeafileUploadStatus = ({ uploading, uploadProgress, uploadInfo }) => {
  return (
    <div>
      {uploading && <p>Uploading... {uploadProgress}%</p>}
      <ul>
        {uploadInfo.map((file, index) => (
          <li key={index}>Uploaded {file.name} ({(file.size / 1024).toFixed(2)} KB)</li>
        ))}
      </ul>
    </div>
  );
};

export default SeafileUploadStatus;
