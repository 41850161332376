// AccDateienBilderVideos.js
import React, { useEffect } from 'react';
import { Accordion, Row, Col, Tabs, Tab } from 'react-bootstrap';
import FormFileSelectIMG from '../form-file-img';
import FormFileSelectVAR from '../form-file-var';
import ContDescr from '../../basic-elements/comp-cont-descr'; // Assuming ContDescr is an imported component


const AccDateienBilderVideos = ({
  values,
  imgnaming,
  imgDispatch,
  countarrayState,
  imgState,
  varfileDispatch,
  varfileState,
  countarrayDispatch,
}) => {
  const {
    projektart = {},
    persondata = [],
    assignedLehrende = [],
    semestertype = {},
    jahr = '',
    selecStgType = '',
    selecStg = {},
    ger_project_tags = [],
    eng_project_tags = [],
    ger_descr_projekt_short = '',
    eng_descr_projekt_short = '',
    ger_descr_projekt_long = '',
    eng_descr_projekt_long = ''
} = values;
    useEffect(() => {
        console.log("AccDateienBilderVideos - imgState updated:", imgState);
      }, [imgState]);
  return (
    <Accordion flush>
      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <h2>Bilder / Videos</h2>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <ContDescr>
              Hinterlege hier die Bilder für die einzelnen Anwendungsbereiche.
              Versuche dabei auf inhaltliche Wiederholungen zu verzichten und
              mittels der Bildabfolge eine Spannungskurve zu erzeugen.
            </ContDescr>
          </Row>
          <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="home" title="Webseite">
              <Row className="m-1"></Row>
              <Row className="gap-3">
                <Col>
                  <FormFileSelectIMG
                    selecStg={selecStg}
                    displayStg={['KD', 'AR', 'MD']}
                    amount_input_field={4}
                    label="Webseite"
                    name="img_Web"
                    imgnaming={imgnaming}
                    desiredWidth={1920}
                    desiredHeight={1080}
                    unit="px"
                    dispatch={imgDispatch}
                    imgState={imgState}
                    cropmark={false}
                    enableAddButton={true}
                    countarrayState={countarrayState}
                  />
                </Col>
              </Row>
            </Tab>

            <Tab eventKey="insta" title="Instagram">
              <Row>
                <Col>
                  <FormFileSelectIMG
                    selecStg={selecStg}
                    displayStg={['KD', 'AR', 'MD']}
                    amount_input_field={4}
                    label="Instagram"
                    name="img_Insta"
                    imgnaming={imgnaming}
                    desiredWidth={1080}
                    desiredHeight={1350}
                    unit="px"
                    dispatch={imgDispatch}
                    imgState={imgState}
                    cropmark={false}
                    enableAddButton={true}
                    countarrayState={countarrayState}
                  />
                  <FormFileSelectVAR
                    label="Instagram Video"
                    name="vid_insta"
                    imgnaming={imgnaming}
                    dispatch={varfileDispatch}
                    varfileState={varfileState}
                  />
                </Col>
              </Row>
            </Tab>

            <Tab eventKey="Youtube" title="Youtube">
              <FormFileSelectIMG
                selecStg={selecStg}
                displayStg={['KD', 'AR', 'MD']}
                amount_input_field={1}
                label="Youtube Thumbnail"
                name="img_youtube"
                imgnaming={imgnaming}
                desiredWidth={1920}
                desiredHeight={1080}
                unit="px"
                dispatch={imgDispatch}
                imgState={imgState}
                cropmark={false}
                enableAddButton={false}
                countarrayState={countarrayState}
              />
              <FormFileSelectVAR
                selecStg={selecStg}
                displayStg={['KD', 'AR', 'MD']}
                amount_input_field={2}
                label="Youtube Video"
                name="youtube_video"
                imgnaming={imgnaming}
                dispatch={varfileDispatch}
                varfileState={varfileState}
                countarrayState={countarrayState}
              />
            </Tab>

            <Tab eventKey="contact" title="Print">
              <Row>
                <Col>
                  <div>
                    <FormFileSelectIMG
                      selecStg={selecStg}
                      displayStg={['KD', 'AR', 'MD']}
                      amount_input_field={5}
                      label="Print"
                      name="img_Print"
                      imgnaming={imgnaming}
                      desiredWidth={3508}
                      desiredHeight={2500}
                      unit="px"
                      dispatch={imgDispatch}
                      imgState={imgState}
                      cropmark={false}
                      enableAddButton={true}
                    />
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccDateienBilderVideos;
