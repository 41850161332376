// AccKontextangaben.js
import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

import FormCompSelect from "../form-select";
import FormSelectLehrende from '../form-select-lehrende';

const AccKontextangaben = ({
  isValid,
  projektart,
  yearOptions,
  selecSemType,
  selecStgType,
  selecStg,
  courses,
  lehrende,
  filteredLehrende,
  values,
  handleSelectChange,
  setFieldValue,
  handleInputChange,
}) => {
  return (
    <Accordion
      flush
      className={classNames("accordion-flush", {
        "accordion-filled": isValid.accordion1,
      })}
    >
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <h2>Kontextangaben</h2>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col>
              <Row>
                <Col md="4" xs="12" className="mb-3">
                  <FormCompSelect
                    name="projektart"
                    labelText="Projektart:"
                    showLabel={false}
                    options={projektart}
                    storeFullObject={true}
                    onChange={(e) => {
                      const { value } = e.target;
                      const selectedOption = projektart.find(
                        (option) => option.value === value
                      );
                      setFieldValue("projektart", selectedOption);
                      handleInputChange("accordion1", "projektart", value);
                      setFieldValue("alumni", value === "FW");
                    }}
                  />
                </Col>
                <Col md="4" xs="12" className="mb-3">
                  <FormCompSelect
                    showLabel={false}
                    name="jahr"
                    labelText="Jahr:"
                    options={yearOptions}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("jahr", value);
                      handleInputChange("accordion1", "jahr", value);
                    }}
                  />
                </Col>
                <Col md="4" xs="12" className="mb-3">
                  <FormCompSelect
                    showLabel={false}
                    name="semestertype"
                    labelText="WiSe / SoSe:"
                    options={selecSemType}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("selecSemType", { value }); // ✅ Update Formik state directly
                      handleInputChange("accordion1", "semestertype", value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col md="4" xs="12" className="mb-3">
                      <FormCompSelect
                        showLabel={false}
                        name="selecStgType"
                        labelText="Typus:"
                        options={selecStgType}
                        onChange={(e) => {
                          const { value } = e.target;
                          handleInputChange("accordion1", "selecStgType", value);
                        }}
                      />
                    </Col>
                    <Col md="4" xs="12"></Col>
                    <Col md="4" xs="12">
                      <FormCompSelect
                        showLabel={false}
                        name="selecStg"
                        labelText="Studiengang:"
                        options={selecStg}
                        onChange={(e) => {
                          const { value } = e.target;
                          const selectedOption = selecStg.find(
                            (option) => option.value === value
                          );
                          setFieldValue("selecStg", selectedOption);
                          handleInputChange("accordion1", "selecStg", value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md="4" xs="12">
                  <FormCompSelect
                    name="modul"
                    labelText="Modul:"
                    options={courses}
                    showLabel={false}
                    storeFullObject={false}
                    onChange={handleSelectChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormSelectLehrende
                    values={values}
                    lehrende={lehrende}
                    filteredLehrende={filteredLehrende}
                    onChange={handleSelectChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccKontextangaben;
