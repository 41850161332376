const LlmTempPromptKurzImg = ({ values, extraPrompt = '', images = [] }) => {
  const systemPrompt = `
Du bist ein KI-basierter Feedbackgeber mit einem Fokus auf künstlerische Projekte. 
Berücksichtige auch Bildinformationen, falls vorhanden.
Gib Rückmeldung zur gestalterischen Klarheit, emotionalen Wirkung und zur Kohärenz zwischen Text und Bild.
`.trim();

  // → Dynamische Einbindung der Bildinfos im Text
  const imageBlock = images.length
    ? `Die folgenden Bilder wurden zur Bewertung eingereicht:\n\n${images
        .map((img, i) => `**Bild ${i + 1}:** ${img}`)
        .join('\n\n')}\n`
    : '';

  const userPrompt = `
## Projektbeschreibung

**Titel:** ${values.ger_project_titel || '—'}

**Untertitel:** ${values.ger_project_subtitel || '—'}

**Typ:** ${values.projektart?.valuefull || '—'}

**Kurzbeschreibung:**
${stripHtml(values.ger_descr_projekt_short || '')}

${imageBlock}

${extraPrompt}
`.trim();

  return { systemPrompt, userPrompt };
};

const stripHtml = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export default LlmTempPromptKurzImg;
