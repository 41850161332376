import React from "react";
import MdInput from "../MD-Input";
import ModList from "../components/mod-list";

const modulelist = () => {
	return (
		<div>
			<div className="mt-5"></div>
			<h1>
				Modulauflistung
				<ModList></ModList>
				{/* <MdInput></MdInput> */}
			</h1>
		</div>
	);
};

export default modulelist;
