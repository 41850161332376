import React from 'react';

import { Button, FormGroup, FormControl, FormLabel, FormSelect, Accordion, Card, Tab, Tabs, Row, Col } from 'react-bootstrap';
import Staff from '../../pages/staff-info';
import Module from '../../pages/module-info';
import ModuleList from '../../pages/module-list';
import StdWork from '../../pages/std-work';
// import StdWorktest from '../../pages/std-worktest';
import Exhib from '../../pages/exh-info';
import StgInfo from '../../pages/stg-info';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

const navApp = ({}) => {
    return (
        
        <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Row>
          <Col>
            <h1 style={{ marginBottom: '0px' }}>Interaktives Modulhandbuch</h1>
          </Col>

          <Col>
            <img src="./logo1.png" alt="Digistart" width="200px" align="right"></img>
            <img src="./logo2.png" alt="Digistart" width="200px" align="right"></img>
          </Col>
        </Row>

        <div class="shadow p-0 mt-2 mb-2 bg-body rounded">
          <nav>
            <Link to="/module-info">Module</Link>
            <Link to="/staff-info">Staff</Link>
            <Link to="/stg-info">Studiengang</Link>
            {/* <Link to="/module-list">Modulliste</Link> */}
            <Link to="/std-work">Studentische Arbeit</Link>
            <Link to="/exh-info">Ausstellung</Link>
          </nav>
        </div>

        <div class="mainContainer">
          <Routes>
            <Route path='/staff-info' element={<Staff />} />
            <Route path='/module-info' element={<Module />} />
            <Route path='/stg-info' element={<StgInfo />} />
            <Route path='/module-list' element={<ModuleList />} />
            <Route path='/std-work' element={<StdWork />} />
            {/* <Route path='/std-worktest' element={<StdWorktest />} /> */}
            <Route path='/exh-info' element={<Exhib />} />
            {/* Define other routes here */}
          </Routes>
        </div>
      </BrowserRouter>
      );
    };
    
    export default navApp;
    