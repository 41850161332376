import React from "react";
import StudentWork from '../components/student-work';


const stdwork = () => {
	return (
		<div>
			<div className="mt-5"></div>
			<h1>
				Studentenarbeit
				<StudentWork/>
			</h1>
		</div>
	);
};

export default stdwork;
