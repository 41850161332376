import {
  Formik,
  Form,
} from "formik";
import React, { useRef, useState, useEffect, useReducer } from "react";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
// import ReactDOMServer from 'react-dom/server';
// import Handlebars from "handlebars";

// Animation
import { useSpring, animated } from "@react-spring/web";
import './react-spring/StudentWorkIntro.css';
import Rivepackageanimation from './abgabepaket/Rive_packageanimation'; // Import the new component


import Icon_export from "./icons/icon-export";
import Icon_folder from "./icons/icon-folder";
import Icon_import from './icons/icon-import';
import Icon_help from './icons/icon-help';

// Importing Components
import FormCompCheckbox from "./formik/form-checkbox";
import HeaderAbgabepaket from './abgabepaket/header-abgabepaket';
 
import CatTitle from "./basic-elements/comp-cattitle";
import DevContainer from "./basic-elements/comp-cont-dev"; // adjust the path as needed
import StudWFooter from "./studw/comp-studw-footer";
// import Modal from 'react-bootstrap/Modal';


// Importing Functions
import { processImageFiles } from "../functions/formik/processfiles/func-processIMGFiles.js";
import { processPDFFiles } from "../functions/formik/processfiles/func-processPDFFiles.js";
import { processVarFiles } from "../functions/formik/processfiles/func-processVarFiles.js";
import { processCountArray } from "../functions/formik/processfiles/func-processCountArray.js";
import { handleFileInputChange } from "../functions/func-handleFileInputChange.js";

import { abgahandleSubmit } from "../functions/abgabepaket/func-abga-handlesubmit.js";
import { generateYearOptions, getCurrentYear, } from "../functions/formik/select-generate-year";
import { transformModuleValue } from "../functions/formik/filename-value"; // Import the externalized function
import { pdfReducer, imgReducer, varfileReducer, countarrayReducer, } from "../functions/formik/fileReducer";
import { formatHtml } from "../functions/handlebar/handlebarTemplateUtils";
import { generateExportNaming } from "../functions/func-generateExportNaming.js";

// Importing Accordions with Formik Content
import AccKontextangaben from './formik/accordions/comp-acc-kontextangaben';
import AccProjektangaben from "./formik/accordions/comp-acc-projektangaben.js";
import AccDateienPDF from "./formik/accordions/comp-acc-pdfdateien";
import AccDateienBilderVideos from './formik/accordions/comp-acc-bildervideo.js';
import AccZusatzdateien from './formik/accordions/comp-acc-zusDateien';
import AccPersKont from './formik/accordions/comp-acc-persKontakt';
import AccTemplateVorschau from './formik/accordions/comp-acc-temp-preview';
import AccFeedback from "./formik/accordions/comp-acc-feedback";
import AccThReflexion from './formik/accordions/comp-acc-ThReflexion';

import AccTemplateIMGGen from './formik/accordions/comp-acc-temp-imggen';

import SeafileUploadAccordion from './formik/accordions/comp-acc-seafileupload.js';

import ModalExport from './abgabepaket/modal-export';

import TemplateInsta from './templates/temp-insta.js'; // Import the new template component
import {
  seafile_uploadFileSW,
  createDirectory,
} from "../functions/const-seafile-upload-SW"; // Adjust the path as necessary

//Const Data based on .json Files
import { selecModMap } from "../const-data/const-selecModMap";
import { tags } from "../functions/const-tags";
import initialValues_Student_Work from "../functions/formik/initialValues-student-work";
import { getUnixTimestamp } from "../functions/func-getunixtimestamp";
import { parseTimestamp, parseTimestampFolder, } from "../functions/const-seafile";

import {
  Button,
  FormGroup,
  Row,
  Col,
} from "react-bootstrap";

import JSZip from "jszip";
import {
  difficulty,
  moduldauer,
  projektart,
} from "../functions/const-stud-work";
import {
  selecStg,
  selecStgSem,
  selecStgType,
  selecSemType,
  getCustomValueofOptionList,
} from "../functions/const-main";


// Get rid of the following part
// Change to react templates
// Handlebars.registerHelper("removeSpaces", function (value) {
//   return value.replace(/\s+/g, "");
// });

// Handlebars.registerHelper("capitalizeFirst", function (value) {
//   if (value && typeof value === "string") {
//     return value.charAt(0).toUpperCase() + value.slice(1);
//   }
//   return value;
// });
// get rid until here


// Start of the Main Component
const StudentWork = () => {

  // Zip Import, Drag and Drop Feature
  const fileInputRef = useRef();
  const [dragOver, setDragOver] = useState(false);


  const [pdfState, pdfDispatch] = useReducer(pdfReducer, {});
  const [imgState, imgDispatch] = useReducer(imgReducer, {});
  const [countarrayState, countarrayDispatch] = useReducer(
    countarrayReducer,
    {}
  );
  const [varfileState, varfileDispatch] = useReducer(varfileReducer, {});
  const [imageData, setImageData] = useState({});
  const [PDFfileName, setFileName] = useState(""); // State variable for the filename
  const yearOptions = generateYearOptions();
  const currentYear = getCurrentYear();
  const [templateContent, setTemplateContent] = useState(null);
  const [renderedTemplate, setRenderedTemplate] = useState("");
  const [uploadInfo, setUploadInfo] = useState([]);
  const [acc_Seafile_showAccordion, acc_Seafile_setShowAccordion] =
    useState(false);
  const [acc_Seafile_activeKey, acc_Seafile_setActiveKey] = useState(null);
  const [courses, setCourses] = useState([]);
  const [manualTrigger, setManualTrigger] = useState(false); // State to trigger useEffect
  const [lehrende, setLehrende] = useState([]);
  const [filteredLehrende, setFilteredLehrende] = useState([]);
  const [imgnaming] = useState([]);

  // Export Modal Overview
  const [showModal, setModalShow] = useState(false);
  const [downloadLink, setDownloadLink] = useState(""); // Store download URL


  // Animation Part
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [wiggle, setWiggle] = useState(false);
  const [showLogos, setShowLogos] = useState(true);

  const [isHovering, setIsHovering] = useState(false);
  const [isClicked, setIsClicked] = useState(false); // New state for click animation

  const handleClick = () => {
    if (!isOpen) {
      setIsClicked(true); // Add class for animation
      setWiggle(true); // Trigger wiggle animation
      setShowLogos(false); // Hide logos immediately

      setTimeout(() => {
        setIsOpen(true); // Show content after animation
        setShowButton(false); // Hide button
        setIsClicked(false); // Remove class after animation
      }, 300); // Animation duration (1.5s)
    }
  };

  // Define the spring for content visibility
  const animationStyles = useSpring({
    to: {
      opacity: isOpen && !showButton ? 1 : 0,
      transform: isOpen && !showButton ? 'scaleY(1)' : 'scaleY(0)',
    },
    from: {
      opacity: 0,
      transform: 'scaleY(0)',
    },
    display: isOpen && !showButton ? 'block' : 'none',
    config: { friction: 10, tension: 210, mass: 0.5 }, // Smooth animation
  });

  // Define the spring for the wiggle effect
  const wiggleAnimation = useSpring({
    to: [
      { rotateZ: 10 },
      { rotateZ: -10 },
      { rotateZ: 10 },
      { rotateZ: -10 },
      { rotateZ: 10 },
      { rotateZ: 0 },
    ],
    from: { rotateZ: 0 },
    reset: wiggle,
    onRest: () => {
      if (wiggle) {
        setWiggle(false);
        if (isOpen) {
          setShowButton(false);
          setShowLogos(false);
        }
      }
    },
    config: { duration: 50 },
    loop: wiggle,
  });

  const handleMouseEnter = () => {
    setIsHovering(true);
    console.log('Hover activated.');
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    console.log('Hover deactivated.');
  };

  // Validation of Accordions
  const [formValues, setFormValues] = useState({
    accordion1: {
      projektart: "",
      jahr: "",
      semestertype: "",
      selecStgType: "",
      selecStg: "",
    },
    accordion2: {
      ger_project_titel: "",
      ger_project_subtitel: "",
      ger_descr_projekt_short: "",
      ger_descr_projekt_long: "",
    },
  });

  const [isValid, setIsValid] = useState({
    accordion1: false,
    accordion2: false,
  });

  // Validation functions
  const validateAccordion = (accordionValues) => {
    console.log("Validating:", accordionValues);
    return Object.keys(accordionValues).every((key) => {
      const isValid =
        accordionValues[key] !== undefined &&
        accordionValues[key] !== null &&
        accordionValues[key].toString().trim() !== "";
      console.log(`Field "${key}" valid:`, isValid);
      return isValid;
    });
  };

  const validateAllAccordions = (formValues) => {
    return {
      accordion1: validateAccordion(formValues.accordion1),
      accordion2: validateAccordion(formValues.accordion2),
    };
  };

  // Trigger validation after formValues update
  useEffect(() => {
    setIsValid(validateAllAccordions(formValues));
  }, [formValues]);

  useEffect(() => {
    console.log("Accordion Validation State Updated:", isValid);
  }, [isValid]);

  // Custom validation trigger function
  const triggerValidation = (updatedValues) => {
    console.log("Triggering validation for updated values:", updatedValues);

    const updatedValidationState = validateAllAccordions(updatedValues);
    console.log("Validation State After Update:", updatedValidationState);

    setIsValid((prevState) => ({
      ...prevState,
      ...updatedValidationState,
    }));
  };



  useEffect(() => {
    const validationResult = validateAllAccordions(formValues);
    console.log("Validation triggered via useEffect. Result:", validationResult);
    setIsValid(validationResult);
  }, [formValues]);

  useEffect(() => {
    console.log("Accordion Validation State Updated in useEffect:", isValid);
  }, [isValid]);


  // Handle field input changes
  const handleInputChange = (accordion, field, value) => {
    setFormValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [accordion]: {
          ...prevValues[accordion],
          [field]: value,
        },
      };

      // Trigger validation
      triggerValidation(updatedValues);

      return updatedValues;
    });
  };


  // const handleImageChange = (
  //   name,
  //   { preview, resolution, base64, filename }
  // ) => {
  //   setImageData({
  //     ...imageData,
  //     [name]: { preview, resolution, base64, filename },
  //   });
  // };

  // useEffect(() => {
  //   const validateAccordion = (accordion) => {
  //     return Object.values(formValues[accordion]).every((value) =>
  //       typeof value === "string" ? value.trim() !== "" : value !== ""
  //     );
  //   };


  //   const newIsValid = {
  //     accordion1: validateAccordion("accordion1"),
  //     accordion2: validateAccordion("accordion2"),
  //   };
  //   setIsValid(newIsValid);

  //   // console.log("Accordion Validation State:", newIsValid);
  //   // console.log("Form Values:", formValues);
  // }, [formValues]);

  // Open modal only when downloadLink is updated
  useEffect(() => {
    if (downloadLink) {
      setModalShow(true);
    }
  }, [downloadLink]);

  // useEffect to process courses based on selection *selecting KD Bachelor
  useEffect(() => {
    const selectedStg = formValues.accordion1?.selecStg;
    const selectedStgType = formValues.accordion1?.selecStgType;

    if (selectedStg && selectedStgType) {
      console.log("Selected Stg:", selectedStg);
      console.log("Selected Stg Type:", selectedStgType);

      const processedCourses = Object.entries(selecModMap)
        .filter(
          ([key, value]) =>
            value.selecStg === selectedStg &&
            value.selecStgType === selectedStgType
        )
        .map(([key, value]) => ({
          value: value.modulnr,
          label: `${value.modulnr} - ${value.ger_modultitel}`,
        }));
      setCourses(processedCourses);

      console.log("Processed Courses:", processedCourses);
    } else {
      const allCourses = Object.entries(selecModMap).map(([key, value]) => ({
        value: value.modulnr,
        label: `${value.modulnr} - ${value.ger_modultitel}`,
      }));
      setCourses(allCourses);
    }
  }, [
    formValues.accordion1?.selecStg,
    formValues.accordion1?.selecStgType,
    manualTrigger,
  ]); // Add manualTrigger to dependencies

  //use effect to change accordion appearance based on validation
  // useEffect(() => {
  //   const validateAccordion = (accordion) => {
  //     return Object.values(formValues[accordion]).every(
  //       (value) => value.trim() !== ""
  //     );
  //   };

  //   const newIsValid = {
  //     accordion1: validateAccordion("accordion1"),
  //     accordion2: validateAccordion("accordion2"),
  //   };
  //   setIsValid(newIsValid);

  //   console.log("Accordion Validation State:", newIsValid);
  //   console.log("Form Values:", formValues);
  // }, [formValues]);

  // function getMaxInputCount(jsonContent) {
  //   let maxInputCount = 0;
  //   for (const key in jsonContent) {
  //     if (key.endsWith("_inputCount") && typeof jsonContent[key] === "number") {
  //       maxInputCount = Math.max(maxInputCount, jsonContent[key]);
  //     }
  //   }
  //   return maxInputCount;
  // }

  const handleSubmitSF = async (values, setValues, setUploadInfo) => {
    console.log("EXPORT IS STARTING NOW");
    console.log("Form values:", values);

    // Debugging statements to check the values
    console.log("values.jahr:", values.jahr);
    console.log("values.selecStg:", values.selecStg);

    const timestamp = getUnixTimestamp();
    const parsed_timestamp = parseTimestampFolder(timestamp);

    // Generate directory name based on form values
    const dirName = generateExportNaming(values);
    const year = values.jahr || "unknown_year";
    const stg = values.selecStg || "unknown_stg";
    const baseUploadPath = process.env.REACT_APP_SF_up_BAMAFW_endpath || "/";
    const yearDir = `${baseUploadPath}${year}/`;
    const stgDir = `${yearDir}${stg}/`;
    const subDirPath = `${stgDir}${dirName}_${parsed_timestamp}/`;

    console.log("baseUploadPath:", baseUploadPath);
    console.log("Year Directory Path:", yearDir);
    console.log("Study Group Directory Path:", stgDir);
    console.log("Sub Directory Path:", subDirPath);

    // Check if environment variables are set correctly
    if (
      !process.env.REACT_APP_SF_proxyUrl ||
      !process.env.REACT_APP_SF_Server
    ) {
      console.error("Missing required environment variable.");
      return;
    }

    // Create directories
    await createDirectory(yearDir);
    await createDirectory(stgDir);
    await createDirectory(subDirPath);

    // Upload files concurrently
    const fileUploadPromises = Object.keys(values).map(async (key) => {
      if (values[key] instanceof File) {
        const fileField = key;
        const filenameField = `${fileField}_filename`;
        const file = values[key];
        const fileType = file.type.split("/")[1]; // Extracts the file type (pdf, jpg, png)

        console.log(`${filenameField} values:`, values[filenameField]);

        let newFileName = values[filenameField] || `defaultName.${fileType}`;
        console.log(`Uploading file: ${newFileName}`);

        // Upload the file to Seafile
        await seafile_uploadFileSW(
          file,
          newFileName,
          subDirPath,
          (progress) => {
            console.log(`Upload progress for ${newFileName}: ${progress}%`);
          },
          (info) => {
            console.log("Upload info:", info);
          }
        );
      }
    });

    await Promise.all(fileUploadPromises);

    // Combine the existing values with additional data
    const combinedData = {
      ...values,
      timestamp: timestamp,
      parsed_timestamp: parseTimestamp(timestamp),
      filename: `${dirName}_${timestamp}`,
    };

    // Stringify the combined data
    const jsonData = JSON.stringify(combinedData, null, 2);
    const jsonBlob = new Blob([jsonData], { type: "application/json" });
    const jsonFile = new File([jsonBlob], `${dirName}_data.json`);




    // Upload the JSON file to Seafile
    await seafile_uploadFileSW(
      jsonFile,
      `${dirName}_data.json`,
      subDirPath,
      (progress) => {
        console.log(`Upload progress for JSON data: ${progress}%`);
      },
      (info) => {
        console.log("Upload info:", info);
      }
    );

    // Optionally update Formik's state
    if (setValues) {
      setValues(combinedData);
    }
  };

  const handleSubmitWithModal = async (values, setValues) => {
    await abgahandleSubmit(values, setValues, setModalShow, setDownloadLink);
  };

  return (


    <Formik initialValues={initialValues_Student_Work} onSubmit={abgahandleSubmit}>
      {({ values, handleChange, setValues, setFieldValue, setTouched }) =>
        (() => {
          const imgnaming = generateExportNaming(values);
          const alumni = values.alumni;
          const handleSelectChange = (e) => {
            const { value } = e.target;
            console.log("Selected Value:", value);
            setFieldValue("accordion1.lehrende", value);

          };
          const handleDropOrSelect = (file) => {
            if (file && (file.name.endsWith(".zip") || file.name.endsWith(".json"))) {
              const mockEvent = {
                target: {
                  files: [file],
                },
              };

              handleFileInputChange(
                mockEvent,
                setFieldValue,
                setTouched,
                setManualTrigger,
                setFormValues,
                values,
                imgDispatch,
                processImageFiles,
                processPDFFiles,
                processVarFiles,
                processCountArray,
                pdfDispatch,
                varfileDispatch,
                countarrayDispatch,
                triggerValidation,
                setManualTrigger,
              );

              handleClick();
            } else {
              alert("Only .zip or .json files are allowed.");
            }
          };

          return (

            <>

              {showLogos && (
                <>
                  <div className="logo_left">
                    <img style={{ height: '70px', margin: '20px' }} src="/img/icons/SVG/Logo_stiftung.svg" />
                  </div>
                  <div className="logo_right">
                    <img style={{ height: '50px', margin: '20px' }} src="/img/icons/SVG/Logo_HT_CG.svg" />
                  </div>
                  <div className="logo_bottoml">
                    Entwickelt von Alois C. Kaufmann
                  </div>
                  <div className="logo_bottom2">
                    
                  <Icon_help width="50px" />
                  </div>
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100vw',
                  height: '100vh',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >

                {showButton && (
                  <>
                    <div>
                      <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={`btn_abgabepaket ${isClicked ? 'clicked' : ''}`}
                        onClick={handleClick}

                        role="button"
                        tabIndex={0}
                        >
                        <Rivepackageanimation
                          src="/abgabepaket.riv" // Make sure this matches your file path
                          stateMachine="abgabepaket" // Matches the state machine name in Rive
                          hoverState="Hovering" // Matches the input name in Rive
                          clickState="click" // Matches the input name in Rive
                          style={{ width: '150px', height: '150px' }}
                          externalHover={isHovering}
                          onClick={handleClick} // Pass the click handler
                        />
                        <p>
                          Abgabepaket
                          <br />
                          starten
                        </p>
                      </div>
                    <div>    

                       <div
                      onDragOver={(e) => {
                        e.preventDefault();
                        setDragOver(true);
                      }}
                      onDragLeave={() => setDragOver(false)}
                      onDrop={(e) => {
                        e.preventDefault();
                        setDragOver(false);
                        const file = e.dataTransfer.files[0];
                        handleDropOrSelect(file);
                      }}
                      onClick={() => fileInputRef.current.click()} // 🔥 this enables classic click
                      style={{
                        border: dragOver ? "2px dashed #A50034" : "2px dashed #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        float:'none',
                        fontSize: "1rem",
                        
                        textAlign: "center",
                        cursor: "pointer",
                        backgroundColor: dragOver ? "#f0f8ff" : "transparent",
                        marginTop: "15px",
                      }}
                      >
                        <Icon_import width="18px" />
                          Drag & Drop ein .zip<br></br> <u>oder klicke hier</u>
                    
                          <input
                          type="file"
                          accept=".json,.zip"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            handleDropOrSelect(file);
                          }}
                        />
                    </div>

                  </div>
                </div>
              </>


                )}

                <div>

                </div>

              </div>


              {isOpen && !showButton && (
                <animated.div className="animatedContent" style={animationStyles}>



                  <Form>
                    <FormGroup>
                      <ModalExport show={showModal} onHide={() => setModalShow(false)}
                        values={values} imgState={imgState}
                        dialogClassName="abgab-modal" // Set the modal size to "xl" for extra large
                      />
                      <HeaderAbgabepaket
                        acc_Seafile_setShowAccordion={acc_Seafile_setShowAccordion}
                        acc_Seafile_setActiveKey={acc_Seafile_setActiveKey}
                        abgahandleSubmit={handleSubmitWithModal}
                        values={values}
                        setValues={setValues}
                        fileInputRef={fileInputRef}
                        handleFileInputChange={handleFileInputChange}
                        setFieldValue={setFieldValue}
                        setTouched={setTouched}
                        setManualTrigger={setManualTrigger}
                        setFormValues={setFormValues}
                        imgDispatch={imgDispatch}
                        pdfDispatch={pdfDispatch}
                        varfileDispatch={varfileDispatch}
                        countarrayDispatch={countarrayDispatch}
                        processImageFiles={processImageFiles}
                        processPDFFiles={processPDFFiles}
                        processVarFiles={processVarFiles}
                        processCountArray={processCountArray}
                      />

                      {process.env.NODE_ENV === "development" && (
                        <DevContainer>
                          <b>Dateibenennung Vorschau:</b> {imgnaming}
                        </DevContainer>
                      )}
                    </FormGroup>

                    
                    <Row>
                      <Col md="6" xs="12">
                        <FormCompCheckbox
                          name="eng_transcr"
                          labelText="Englische Übersetzung hinterlegen"
                        />
                      </Col>

                      <Col md="6" xs="12">
                        <FormCompCheckbox
                          name="alumni"
                          labelText="Ich bin MA / BA AbsolventIn oder mache dieses Semester meinen Abschluss"
                        />
                      </Col>

                    </Row>

                    {/* <SeafileUploadAccordion
                showAccordion={acc_Seafile_showAccordion}
                activeKey={acc_Seafile_activeKey}
                iframeSrc="https://seafile.custom-url.net/u/d/your-custom-link"
              /> */}

                    <CatTitle IconComponent={Icon_export} titleText="Metadaten" />

                    <AccKontextangaben
                      isValid={isValid}
                      projektart={projektart}
                      yearOptions={yearOptions}
                      selecSemType={selecSemType}
                      selecStgType={selecStgType}
                      selecStg={selecStg}
                      courses={courses}
                      lehrende={lehrende}
                      filteredLehrende={filteredLehrende}
                      values={values}
                      handleSelectChange={handleSelectChange}
                      setFieldValue={setFieldValue}
                      handleInputChange={handleInputChange}
                    />
                    <AccProjektangaben
                    
                      isValid={isValid}
                      tags={tags}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleInputChange={handleInputChange}
                    />
                    <AccPersKont
                      selecStgSem={selecStgSem}
                      handleInputChange={handleInputChange}
                      setFieldValue={setFieldValue}
                      alumni={alumni}
                    />


                    {/* This Part could be a conditional logic based on stg selection */}
                    <CatTitle IconComponent={Icon_folder} titleText="Dateien" />

                    <AccDateienPDF
                      values={values}
                      imgnaming={imgnaming} 
                      countarrayState={countarrayState} 
                      countarrayDispatch={countarrayDispatch} 
                      pdfDispatch={pdfDispatch}yy
                      pdfState={pdfState} />

                    <AccDateienBilderVideos 
                      values={values}
                      imgnaming={imgnaming}
                      imgDispatch={imgDispatch}
                      countarrayDispatch={countarrayDispatch}
                      imgState={imgState}
                      varfileDispatch={varfileDispatch}
                      varfileState={varfileState}
                      countarrayState={countarrayState}
                    />

                    <AccZusatzdateien
                      values={values}
                      imgnaming={imgnaming}
                      varfileDispatch={varfileDispatch}
                      varfileState={varfileState}
                      countarrayState={countarrayState}
                    />

                    <AccThReflexion
                      displayStg={['KD', 'AR', 'MD']}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleInputChange={handleInputChange}
                      />

                    <CatTitle IconComponent={Icon_folder} titleText="Templates" />
                    <AccTemplateVorschau values={values} />

                    {/* end of conditional logic */}

                    

                    {/* Script still in progress */}
                    {/* <AccTemplateIMGGen values={values} /> */}
                    {/* <CatTitle IconComponent={Icon_export} titleText="Lizenzierung" /> */}


                    
                   

                    <CatTitle IconComponent={Icon_folder} titleText="Feedback" />
                    <AccFeedback
                      values={values}
                      setFieldValue={setFieldValue}
                      handleInputChange={handleInputChange}
                    />

                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          type="button"
                          className="custom-button"
                          style={{
                            height: "60%",
                            minWidth: "150px",
                            width: "100%",
                            minHeight: "50px",
                          }}
                          onClick={() => {
                            acc_Seafile_setShowAccordion(true); // Toggle visibility state
                            acc_Seafile_setActiveKey("0"); // Set the Accordion item to open automatically
                            abgahandleSubmit(values, setValues);
                          }}
                        >
                          <Icon_export width="18px" />.zip Datei exportieren
                        </Button>
                      </Col>
                    </Row>
                    {process.env.NODE_ENV === "development" && (
                      <DevContainer>
                        <b>Vorschau Export Texte:</b> {imgnaming} / Projektart:{" "}
                        {values.projektart.valuefull} / Studiengang:{" "}
                        {values.selecStg.valuefull}
                      </DevContainer>
                    )}
                    <StudWFooter />
                  </Form>

                </animated.div>
              )}
            </>












          );










        })()
      }
    </Formik>
  );
};
export default StudentWork;