import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './btrap-tooltip.css'; // Import custom styles



const CustomTooltip = ({ message, children }) => {
    // If no message is provided, just return the children without tooltip
    if (!message) {
      return children;
    }
  
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="tooltip-bottom" className="custom-tooltip">
            {message}
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    );
  };
  
  export default CustomTooltip;