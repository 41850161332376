// components/abgabepaket/comp-genpdf.jsx
import React from "react";

export default function PagedFactsheet({ values }) {
  const stripHtmlTags = (html = "") =>
    html.replace(/<[^>]*>/g, "").replace(/\n/g, " ").trim();

  const formatTags = (tags = []) =>
    tags
      .filter((t) => t.value)
      .map((t) => `#${t.value.replace(/\s+/g, "")}`)
      .join(" ");

  const renderImages = (label, count, prefix) => {
    const items = [];
    for (let i = 1; i <= count; i++) {
      const field = values[`${prefix}_${i}`];
      const filename = values[`${prefix}_${i}_filename`];
      const url = values[`${prefix}_${i}_url`];
      if (field instanceof File && url) {
        items.push(
          <div key={i} className="thumb">
            <img src={url} alt={filename} />
            <div className="caption">{filename}</div>
          </div>
        );
      }
    }
    return items.length > 0 ? (
      <section className="image-section">
        <h3>{label}</h3>
        <div className="img-grid">{items}</div>
      </section>
    ) : null;
  };

  return (
    <div className="paged-factsheet">
      <style>{`
        @page {
          margin: 2cm;
          size: A4;
        }

        .paged-factsheet {
          font-family: 'Helvetica Neue', sans-serif;
          font-size: 11pt;
          color: #222;
          padding: 1rem;
          line-height: 1.5;
        }

        h1 {
          font-size: 20pt;
          margin-bottom: 0.2em;
        }

        h2 {
          font-size: 16pt;
          margin-top: 1.5em;
          margin-bottom: 0.5em;
          border-bottom: 2px solid #999;
          padding-bottom: 0.3em;
        }

        h3 {
          font-size: 13pt;
          margin-top: 1.2em;
          margin-bottom: 0.4em;
          color: #555;
        }

        table.meta {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 1em;
        }

        table.meta td {
          padding: 4px 8px;
          vertical-align: top;
        }

        hr {
          border: none;
          border-top: 1px solid #bbb;
          margin: 2em 0;
        }

        .img-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 0.5em;
        }

        .thumb {
          width: calc(33.333% - 10px);
          font-size: 9pt;
        }

        .thumb img {
          width: 100%;
          height: auto;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        .caption {
          margin-top: 4px;
          text-align: center;
          color: #666;
        }

        .image-section {
          margin-top: 2em;
        }
      `}</style>

      <h1>{values.ger_project_titel || "Projekttitel"}</h1>

      <table className="meta">
        <tbody>
          <tr>
            <td><strong>Semester:</strong></td>
            <td>{values.semestertype?.value || ""}</td>
            <td><strong>Studiengang:</strong></td>
            <td>{values.selecStg?.valuefull || ""}</td>
          </tr>
          <tr>
            <td><strong>Jahr:</strong></td>
            <td>{values.jahr || ""}</td>
            <td><strong>Modul:</strong></td>
            <td>{values.modul || ""}</td>
          </tr>
        </tbody>
      </table>

      <hr />

      {formatTags(values.ger_project_tags) && (
        <p><strong>Tags:</strong> {formatTags(values.ger_project_tags)}</p>
      )}

      {values.ger_descr_projekt_short && (
        <p dangerouslySetInnerHTML={{ __html: values.ger_descr_projekt_short }} />
      )}

      {values.ger_descr_projekt_long && (
        <>
          <h2>Beschreibung</h2>
          <div
            dangerouslySetInnerHTML={{ __html: values.ger_descr_projekt_long }}
          />
        </>
      )}

      {renderImages("Web Images", values.img_Web_inputCount || 0, "img_Web")}
      {renderImages("Instagram Images", values.img_Insta_inputCount || 0, "img_Insta")}
      {renderImages("YouTube Images", values.img_youtube_inputCount || 0, "img_youtube")}
    </div>
  );
}
