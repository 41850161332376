import React from 'react';

const DPICalculator = ({ pixelWidth, pixelHeight, paperWidthCm, desiredResolution }) => {
  // Validate input
  if (typeof pixelWidth !== 'number' || typeof pixelHeight !== 'number' || 
      typeof paperWidthCm !== 'number' || typeof desiredResolution !== 'number') {
    return null;
  }

  // Calculate the aspect ratio of the image
  const aspectRatio = pixelWidth / pixelHeight;

  // Calculate the paper height in cm based on the aspect ratio
  const paperHeightCm = paperWidthCm / aspectRatio;

  // Convert cm to inches for DPI calculation
  const paperWidthInches = paperWidthCm / 2.54;
  const paperHeightInches = paperHeightCm / 2.54;

  // Calculate DPI
  const dpiWidth = pixelWidth / paperWidthInches;
  const dpiHeight = pixelHeight / paperHeightInches;

  // Check if DPI is below the threshold
  const isDpiLow = dpiWidth < desiredResolution || dpiHeight < desiredResolution;
  
  // Calculate required pixel dimensions
  const requiredPixelWidth = desiredResolution * paperWidthInches;
  const requiredPixelHeight = desiredResolution * paperHeightInches;

  return (
    <div className="warning">
      <p>Aktuelle Druckauflösung: <strong>{dpiWidth.toFixed(2)} DPI</strong><br></br>
      bei einer Bildgrösse von {paperWidthCm.toFixed(1)}cm / {paperHeightCm.toFixed(1)}cm</p>
    
      {isDpiLow && <div className="warning">
      <p>Die benötigte Pixelanzahl bei einer gewünschten Auflösung von {desiredResolution} DPI und einer Medienbreite von {paperWidthCm.toFixed(1)}cm beträgt:<br></br>
      <strong>{Math.round(requiredPixelWidth)}px x {Math.round(requiredPixelHeight)}px</strong></p>
      </div>}
    </div>
  );
};

export default DPICalculator;
