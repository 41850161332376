import React, { useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Extend Quill Clipboard to paste plain text only
const Clipboard = Quill.import('modules/clipboard');

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const plainText = (e.clipboardData || window.clipboardData).getData('text/plain');
    const range = this.quill.getSelection();
    this.quill.insertText(range.index, plainText);
    this.quill.setSelection(range.index + plainText.length);
  }
}

Quill.register('modules/clipboard', PlainClipboard, true);




const QuillEditorStaffv2 = ({ value, onChange, fieldname, maxChars }) => {
  const [editorHtml, setEditorHtml] = useState(value);
  const [charCount, setCharCount] = useState(0);
  const [gptResponse, setGptResponse] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEditorHtml(value);
    setCharCount(value ? value.replace(/<(.|\n)*?>/g, '').length : 0);
  }, [value]);

  const handleChange = (content, delta, source, editor) => {
    const visibleText = content.replace(/<(.|\n)*?>/g, '').trim();
    const visibleCharCount = visibleText.length;

    if (visibleCharCount <= maxChars || (delta.ops.some(op => op.insert === '\n' && visibleCharCount <= maxChars))) {
      setEditorHtml(content);
      setCharCount(visibleCharCount);
      onChange(content);
    } else {
      const truncatedContent = content.substring(0, maxChars);
      setEditorHtml(truncatedContent);
      setCharCount(maxChars);
      onChange(truncatedContent);
      console.log("Character limit reached");
    }
  };


  const handleSendToChatGPT = async () => {
    setLoading(true);
    setGptResponse('');
  
    const parser = new DOMParser();
    const doc = parser.parseFromString(editorHtml, 'text/html');
    const plainText = doc.body.textContent || '';
  
    const prompt = `Analyze the following input:\n\n${plainText}`;
  
    try {
      const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_GROQ_APIKEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'gemma2-9b-it', // Or another supported Groq model
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: prompt },
          ],
          temperature: 0.7,
        }),
      });
  
      const data = await response.json();
      console.log('Groq response:', data);
  
      if (data.choices && data.choices.length > 0) {
        setGptResponse(data.choices[0].message.content);
      } else if (data.error) {
        setGptResponse(`Error: ${data.error.message}`);
      } else {
        setGptResponse('Unexpected response structure. Check console.');
      }
    } catch (err) {
      console.error('Groq API error:', err);
      setGptResponse('An error occurred while contacting Groq.');
    } finally {
      setLoading(false);
    }
  };
  

  // const handleSendToChatGPT = async () => {
  //   setLoading(true);
  //   setGptResponse('');
  
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(editorHtml, 'text/html');
  //   const plainText = doc.body.textContent || '';
  
  //   const prompt = `Analyze the following input:\n\n${plainText}`;
  
  //   try {
  //     const response = await fetch('https://api.openai.com/v1/chat/completions', {
  //       method: 'POST',
  //       headers: {
  //         Authorization: `Bearer ${process.env.REACT_APP_CHATGPT_APIKEY}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         model: 'gpt-3.5-turbo',
  //         messages: [
  //           { role: 'system', content: 'You are a helpful assistant.' },
  //           { role: 'user', content: prompt },
  //         ],
  //         temperature: 0.7,
  //       }),
  //     });
  
  //     const data = await response.json();
  //     console.log('Raw ChatGPT API response:', data); // ← Now it's in the right place
  
  //     if (data.choices && data.choices.length > 0) {
  //       setGptResponse(data.choices[0].message.content);
  //     } else if (data.error) {
  //       setGptResponse(`Error: ${data.error.message}`);
  //     } else {
  //       setGptResponse('Unexpected response structure. Check console.');
  //     }
  //   } catch (err) {
  //     console.error('ChatGPT error:', err);
  //     setGptResponse('An error occurred while contacting ChatGPT.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  const charCountClass = charCount > maxChars ? 'quillcount limit-reached' : 'quillcount';

  return (
    <div>
      <ReactQuill
        className="quillEditor"
        value={editorHtml}
        onChange={handleChange}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={['bold', 'italic', 'underline', 'strike', 'link']}
        placeholder="Start typing here..."
      />
      <div className={charCountClass}>
        Anzahl Zeichen: {charCount} / {maxChars}
      </div>

      <button onClick={handleSendToChatGPT} disabled={loading} style={{ marginTop: '10px' }}>
        {loading ? 'Sending to ChatGPT...' : 'Send to ChatGPT'}
      </button>

      {gptResponse && (
        <div className="gpt-response" style={{ marginTop: '15px', whiteSpace: 'pre-wrap', background: '#f9f9f9', padding: '10px', borderRadius: '5px' }}>
          <strong>ChatGPT Response:</strong>
          <div>{gptResponse}</div>
        </div>
      )}
    </div>
  );
};

export default QuillEditorStaffv2;
