import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import { Row, Col, ProgressBar, Alert } from 'react-bootstrap';

import zipFileNames from '../const-data/const-std-work';

const Exhiblist = () => {
    const [projects, setProjects] = useState([]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const fetchAndExtractZip = async (fileName, index, total) => {
            const filePath = `${process.env.PUBLIC_URL}/std-work/${fileName}`;

            try {
                const response = await fetch(filePath);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}, URL: ${filePath}`);
                }

                const contentType = response.headers.get("Content-Type");
                if (contentType && !contentType.includes("application/zip")) {
                    throw new Error(`Expected application/zip but received ${contentType}`);
                }

                const blob = await response.blob();
                if (blob.size === 0) {
                    throw new Error("Received empty file");
                }

                const arrayBuffer = await blob.arrayBuffer();
                const zip = await JSZip.loadAsync(arrayBuffer);
                const files = zip.files;

                const images = [];
                let jsonData = {};
                let youtubeWarning = false;  // Assume no warning by default

                for (const filename in files) {
                    if (filename.endsWith('data.json')) {
                        const fileData = await files[filename].async('string');
                        const fullJsonData = JSON.parse(fileData);
                        youtubeWarning = !fullJsonData.check_youtube_allowance;  // Set the flag based on the actual data

                        jsonData = {
                            ...fullJsonData,
                            assignedLehrende: fullJsonData.assignedLehrende.map(l => l.ger_lehrgebiet),
                            ger_project_tags: (fullJsonData.ger_project_tags || []).map(tag => tag.label).filter(label => label && label.trim().length > 0),
                        };
                    } else if (filename.match(/insta_[1-3].*\.(jpg|png|jpeg)$/i)) {
                        const fileData = await files[filename].async('base64');
                        images.push(`data:image/jpeg;base64,${fileData}`);
                    }
                }

                setProgress(prevProgress => prevProgress + (1 / total * 100));
                return { images, jsonData, youtubeWarning };  // Return the correct flag
            } catch (error) {
                return null;
            }
        };

        const processFiles = async () => {
            const validZipFiles = zipFileNames.filter(name => name.endsWith('.zip'));
            const totalFiles = validZipFiles.length;
            const projectDataPromises = validZipFiles.map((fileName, index) => fetchAndExtractZip(fileName, index, totalFiles));
            const projectData = await Promise.all(projectDataPromises);
            setProjects(projectData.filter(p => p !== null));
        };

        processFiles();
    }, []);

    return(

        <div>
<ProgressBar now={progress} label={`${progress.toFixed(2)}%`} />

            {projects.map((project, index) => (
                <>
                    <div key={index} style={{ borderTop: '1px solid black', marginTop:'20px', marginBottom: '20px', padding: '0px', backgroundColor: 'whitesmoke' }}>
                    
                        <Row>
                            
                            <Col md={5}>
                                {project.images.map((img, imgIndex) => (
                                    <img key={imgIndex} src={img} alt={`Project Image ${imgIndex + 1}`} style={{Height:'200px', maxWidth:'300px', marginRight: '10px' }} />
                                ))}
                            </Col>
                          
                            <Col md={7} style={{borderLeft:'0px solid black'}}>
                            <div style={{padding:'10px', color:'white', float:'left', backgroundColor:'#A50034', marginRight:'20px'}}>{project.jsonData.selecStg}</div>
                                <div style={{marginLeft:'80px'}}>
                                    
                                    <h1>{project.jsonData.ger_project_titel}</h1>
                                    <h2>{project.jsonData.ger_project_subtitel}</h2>
                                    {project.jsonData.selectStgType}
                                    <div style={{color:'grey'}}>
                                    <Row>
                                        <Col>
                                        {project.jsonData.vorname} {project.jsonData.nachname}<br></br>
                                        {project.jsonData.jahr} {project.jsonData.semestertype}
                                        
                                        <div>{project.jsonData.assignedLehrende.join(', ')}</div>
                                        <hr></hr>
                                        {project.jsonData.ger_project_tags.map((tag, index) => (
                                                    <div key={index} style={{ background: '#e1e1e1', float:'left', borderRadius: '10px', padding: '5px 10px', marginRight: '5px', marginBottom: '5px', marginTop:'5px', fontSize: '0.8em' }}>
                                                        {tag}
                                                    </div>
                                                ))}
                           
                                        </Col>
                                        <Col>
                                        Projekt übermittelt: {project.jsonData.parsed_timestamp}
                                        {project.youtubeWarning && <Alert variant="danger">YouTube sharing not allowed for this project.</Alert>}
                                        </Col>
                                    </Row>
                                        
                                    
                                        
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div>

                        </div>
                    </div>
                </>
            ))}
        </div>






    );
};

export default Exhiblist;
