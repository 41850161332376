export const selecStaffMap = {
  "AR/staff-AR-Prof-Andrea-Wandel-.json": {
    "filename": "AR/staff-AR-Prof-Andrea-Wandel-.json",
    "fullname": "Prof. Andrea Wandel",
    "email": "wandel@ar.hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR",
        "selecStgType": "bachelor",
        "selecStgSem": "6"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "AR",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p><br></p>",
    "ger_lehrgebiet": "Entwerfen, Raumbildung und Darstellung"
  },
  "AR/staff-AR-Prof-Christoph-Klanten-.json": {
    "filename": "AR/staff-AR-Prof-Christoph-Klanten-.json",
    "fullname": "Prof. Christoph Klanten",
    "email": "C.Klanten@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "Städtebau"
  },
  "AR/staff-AR-Prof-Daniel-Berger-.json": {
    "filename": "AR/staff-AR-Prof-Daniel-Berger-.json",
    "fullname": "Prof. Daniel Berger",
    "email": "D.Berger@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Ausgangspunkt für die Lehre der Baukonstruktion ist das Verständnis von Entwurf und Konstruktion als Einheit. Die Studierenden lernen zu Erkennen wie Kontext, Entwurf und Konstruktion in der Architektur zusammenhängen. Hierbei stehen das Material als Werkstoff, die Struktur des Gebäudes und seine logische Ordnung sowie die Angemessenheit der Mittel (ökonomisch wie rational) als Einﬂussgrößen für die verbindende Idee - das Architekturkonzept - im Fokus der Betrachtungen. Das Konzept ist im Hinblick auf die beabsichtigte Realisierung und seiner Tragfähigkeit im gebauten Zustand und Umfeld stets kritisch zu prüfen. Für die Lehre ist es deshalb wichtig ein Gefühl für die Abhängigkeiten der Bedingungen für Architektur zu vermitteln, kreative Einﬂussnahmen in das dichte Geﬂecht der Beziehungen zu üben und auf die gestalterischen Konsequenzen hinzuweisen.</p>",
    "ger_lehrgebiet": "Konstruieren und Entwerfen"
  },
  "AR/staff-AR-Prof-Jan-Henrik-Hafke-.json": {
    "filename": "AR/staff-AR-Prof-Jan-Henrik-Hafke-.json",
    "fullname": "Prof. Jan-Henrik Hafke",
    "email": "J.Hafke@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>„To raise the question of typology in architecture is to raise the question of the architectural work itself.“ Rafael Moneo, „On Typolo“, 1978</p><p><br></p><p>Was ist Typologie? Das Verhältnis zwischen Programm und Form? Die Lehre und der Diskurs um den&nbsp;architektonischen Typen? Gebäudelehre sollte im besten Falle ein Spannungsfeld zwischen den unterschiedlichen Bedeutung und Deutungen des Begriffes Typologie aufzeichnen – zwischen den Regeln einer funktional determinierten Typologie auf der einen Seite, der Lehre von architektonischen (Arche-) Typen andererseits, die Ihren Sinn in der Suche nach den - dem Gebäude innewohnenden - wesentlichen Strukturen sieht. Und schließlich den alternativen Konzepten, die etwa aus den Einflüssen digitaler Entwicklungen oder dem Einfluss andere Kulturen resultieren. Dies setzt&nbsp;ein Bewusstsein und Wissen typologischer Vorbilder und Bezüge im Sinne von Typus und Topos voraus.</p>",
    "ger_lehrgebiet": "Entwerfen und Gebäudelehre"
  },
  "AR/staff-AR-Prof-Matthias-Sieveke-.json": {
    "filename": "AR/staff-AR-Prof-Matthias-Sieveke-.json",
    "fullname": "Prof. Matthias Sieveke",
    "email": "M.Sieveke@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Mein didaktisches Ziel als Professor und Leiter des o.g. Lehrgebietes ist die Entwicklung und Anwendung von nachhaltigen Konzepten für energiesparende Gebäude im nationalen und internationalen Kontext. Aufgrund ihrer wechselseitigen Beziehungen sind die Konstruktion eines Gebäudes, die Gebäudehülle, Ihre Materialisierung und eine zeitgemäße Gebäudetechnologien eng mit der Reduzierung des Gebäudeenergieverbrauchs sowie mit der \"Gestalt\" architektonischer Projektionen verbunden.</p><p><br></p><p>Aspekte der ortsspezifischen äußeren Bedingungen, z. B Einstrahlung, Niederschläge und Windrichtung erfordern neben das Wissen um baustrukturelle Zusammenhänge ein Verständnis über naturwissenschaftliche Zusammenhänge.</p><p><br></p><p>Der Betrachtungshorizont der Zusammenhänge und Wechselwirkungen geht über das unmittelbar ortsbezogene des Regionalen hinaus. In den Masterseminaren ist die Betrachtung unterschiedlicher Klimazonen und Ihrer baulichen Auswirkungen Grundlage des Studiums. Durch die differenzierte Herangehensweise lernen die Studierenden unterschiedliche Strategien zur Energiereduktion im baulichen Bereich kennen. Die Komplexität erfordert ein theoretisches Grundlagenstudium und die Auseinandersetzung anwendungsorientier Übungen und Seminaren.</p>",
    "ger_lehrgebiet": "Konstruktion und Gebäudetechnologie"
  },
  "AR/staff-AR-Prof-Michael-Schwarz-.json": {
    "filename": "AR/staff-AR-Prof-Michael-Schwarz-.json",
    "fullname": "Prof. Michael Schwarz",
    "email": "N/A",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p><br></p>",
    "ger_lehrgebiet": "Baudenkmalpflege"
  },
  "AR/staff-AR-Prof-Petra-Riegler-Floors-.json": {
    "filename": "AR/staff-AR-Prof-Petra-Riegler-Floors-.json",
    "fullname": "Prof. Petra Riegler-Floors",
    "email": "P.Riegler-Floors@ar.hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "Zirkuläres Bauen, Konsturktion und Material"
  },
  "AR/staff-AR-Prof-Robert-Thum-.json": {
    "filename": "AR/staff-AR-Prof-Robert-Thum-.json",
    "fullname": "Prof. Robert Thum",
    "email": "R.Thum@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Das Lehrgebiet \"Computational Design in Architecture\" erforscht die Auswirkungen der Digitalisierung in der Architektur durch Seminare und praxisorientierte Entwurfsaufgaben. Die Digitalisierung ermöglicht die systematische Wiederverwendung von Bauelementen, was die Verschwendung von wertvollen Ressourcen und Abfall reduziert. Die Verbindung von digitalen Entwurf mit computergesteuerter Fertigung und Bauabwicklung verbessert die Präzision und Effizienz in der Architektur. Die Lehre dieses Fachgebiets betont die Vermittlung allgemeiner Prinzipien, die unabhängig von technologischen Entwicklungen sind, und die vermittelte Theorie und Methodik sind in verschiedenen architektonischen Kontexten anwendbar.</p>",
    "ger_lehrgebiet": "Computational Design in Architecture"
  },
  "ES/staff-ES-Prof-Eva-Maria-Kollischan.json": {
    "filename": "ES/staff-ES-Prof-Eva-Maria-Kollischan.json",
    "fullname": "Prof. Eva-Maria Kollischan",
    "email": " E.Kollischan(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ES",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ES",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p><strong>Sehen lernen</strong></p><p>Durch Experiment und spielerischen Umgang werden Erfahrungen gemacht mit dem Ziel, den Charakter des eigenen bildnerischen Ausdrucks zu entdecken und zu entwickeln.&nbsp;</p><p>Die Auseinandersetzung mit Basisbegriffen der künstlerischen Grundlagen, wie Form, Farbe, Gewicht, Größe, Proportion und Oberfläche bildet den Ausganspunkt für die Lehre.&nbsp;</p><p>Forschen im Bereich des Bildnerischen Denkens mit Bezug zu Körper und Raum setzt diese Erfahrungen fort.&nbsp;</p><p>Das eigene Schaffen wird reflektiert mit Bezug auf die Rezeption von zeitgenössischen und historischen Positionen.</p>",
    "ger_lehrgebiet": "künstlerische Gestaltung"
  },
  "ES/staff-ES-Prof-Theo-Smeets-.json": {
    "filename": "ES/staff-ES-Prof-Theo-Smeets-.json",
    "fullname": "Prof. Theo Smeets",
    "email": " smeets(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ES",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ES",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p><strong>Lehrkonzept: Schmuckgestaltung mit eigener Handschrift</strong></p><p>Die Entwicklung einer eigenen Formensprache steht im Studienfach Schmuckdesign im Vordergrund - die Auseinandersetzung mit Gestaltung als eigenständige kreative Leistung steht an erster Stelle. Diesem Ziel dienen alle Studienprojekte, von rein künstlerisch-wissenschaftlich bis hin zu Dienstleistungsprojekten für Unternehmen. Absolventen/-innen werden so in die Lage versetzt, später - in selbstständiger oder angestellter Tätigkeit - Schmuck zu gestalten, der eine eigene Handschrift trägt.</p>",
    "ger_lehrgebiet": "Schmuckgestaltung"
  },
  "ES/staff-ES-Prof-Ute-Eitzenhöfer-.json": {
    "filename": "ES/staff-ES-Prof-Ute-Eitzenhöfer-.json",
    "fullname": "Prof. Ute Eitzenhöfer",
    "email": "U.Eitzenhoefer(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ES",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ES",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p><strong>Lehrkonzept: Offenheit als Grundlage jeder kreativen Tätigkeit</strong></p><p>Die Formulierung des Selbstverständnisses von Gestalterinnen und Gestaltern, also die Frage nach dem Wesen der Gestaltung steht an erster Stelle des Studienfachs. Die Erforschung der Gestaltungskriterien unter ästhetischen, ökonomischen und ethischen Gesichtspunkten und Offenheit als Grundlage jeder kreativen Tätigkeit dienen dazu, ein kritisches Bewusstsein zu bilden und dieses zu formulieren.</p><p>Neben der künstlerisch-experimentellen oder systematischen Auseinandersetzung mit Edelstein und seinen Eigenschaften wird seine Rolle im Schmuck und in der Gesellschaft hinterfragt. In den Studienprojekten geht es im Hinblick auf die spätere gestalterische Tätigkeit ebenso um die selbstständige Weiterentwicklung von Lehrinhalten, das Analysieren, Reflektieren und Herstellen der theoretischen und praktischen Bezüge von Edelstein und Schmuck, wie um Eigeninitiative, das Einordnen von Erfahrungen und die Grenzen der Gestaltung.</p>",
    "ger_lehrgebiet": "Edelsteingestaltung"
  },
  "IA/staff-IA-Prof-Dirk Miguel-Schluppkotten-.json": {
    "filename": "IA/staff-IA-Prof-Dirk Miguel-Schluppkotten-.json",
    "fullname": "Prof. Dirk Miguel Schluppkotten",
    "email": "schluppk(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor",
        "selecStgSem": "8"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master",
        "selecStgSem": "2"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "N/A"
  },
  "IA/staff-IA-Prof-Ingo-Krapf-.json": {
    "filename": "IA/staff-IA-Prof-Ingo-Krapf-.json",
    "fullname": "Prof. Ingo Krapf",
    "email": " krapf(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "Produkt-, Möbel und Ausstellungsdesign PMAD"
  },
  "IA/staff-IA-Prof-Kirsi Susanna-Leppäkoski-.json": {
    "filename": "IA/staff-IA-Prof-Kirsi Susanna-Leppäkoski-.json",
    "fullname": "Prof. Kirsi Susanna Leppäkoski",
    "email": "K.Leppaekoski(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "Innenarchitektur und Narrativer Raum"
  },
  "IA/staff-IA-Prof-Marcus-Kaiser-.json": {
    "filename": "IA/staff-IA-Prof-Marcus-Kaiser-.json",
    "fullname": "Prof. Marcus Kaiser",
    "email": " M.Kaiser(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "Innenarchitektonisches Konstruieren und Bauen im Bestand"
  },
  "IA/staff-IA-Prof-Martin-Schroth-.json": {
    "filename": "IA/staff-IA-Prof-Martin-Schroth-.json",
    "fullname": "Prof. Martin Schroth",
    "email": " M.Schroth(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "Digitales Konstruieren und Entwerfen"
  },
  "IA/staff-IA-Prof-Stefan-Dornbusch-.json": {
    "filename": "IA/staff-IA-Prof-Stefan-Dornbusch-.json",
    "fullname": "Prof. Stefan Dornbusch",
    "email": " S.Dornbusch(at)hochschule-trier.de ",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "Zeichnen und Gestaltungsgrundlagen ZUGG"
  },
  "ID/staff-ID-Prof-Daniel-Gilgen-.json": {
    "filename": "ID/staff-ID-Prof-Daniel-Gilgen-.json",
    "fullname": "Prof. Daniel Gilgen",
    "email": " D.Gilgen(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ID",
        "selecStgType": "bachelor",
        "selecStgSem": "6"
      },
      {
        "roleItem": "",
        "selecStg": "ID",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Sein Lehrgebiet umfasst neue Formen der Interaktion mit Computersystemen, deren Wahrnehmung und deren Durchdringung des Raumes.</p>",
    "ger_lehrgebiet": "Medienräume, Medial Raum und Umweltgestaltung"
  },
  "ID/staff-ID-Prof-Gregor-Kuschmirz-.json": {
    "filename": "ID/staff-ID-Prof-Gregor-Kuschmirz-.json",
    "fullname": "Prof. Gregor Kuschmirz",
    "email": "G.Kuschmirz(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Der Schwerpunkt seiner Lehre liegt auf dem Zusammenspiel unterschiedlicher medialer Ausdrucksformen, der Vermittlung künstlerischer Praktiken und medientheoretischer Positionen.</p>",
    "ger_lehrgebiet": "Intermediale Ästhetik"
  },
  "ID/staff-ID-Prof-Linda-Breitlauch-.json": {
    "filename": "ID/staff-ID-Prof-Linda-Breitlauch-.json",
    "fullname": "Prof. Linda Breitlauch",
    "email": " L.Breitlauch(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Sie lehrt und forscht mit besonderem Fokus auf interaktivem Storytelling, Serious Games und Gamification sowie Dramaturgie</p>",
    "ger_lehrgebiet": "Games und 3D"
  },
  "ID/staff-ID-Prof-Servet-Golbol-.json": {
    "filename": "ID/staff-ID-Prof-Servet-Golbol-.json",
    "fullname": "Prof. Servet Golbol",
    "email": " S.Golbol(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Sein&nbsp;Fokus liegt auf Regie im fiktionalen und dokumentarischen Bereich sowie auf&nbsp;Dramaturgie und Drehbuch.</p>",
    "ger_lehrgebiet": "Narrative Formate"
  },
  "MD/staff-IA-MD-Prof-Christina-Threuter-.json": {
    "filename": "MD/staff-IA-MD-Prof-Christina-Threuter-.json",
    "fullname": "Prof. Christina Threuter",
    "email": "C.Threuter(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor",
        "selecStgSem": "8"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master",
        "selecStgSem": "2"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "master",
        "selecStgSem": "3"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Geschichte der Bekleidung/ Spezialisierung: Visuelle und Materielle Kultur: Mode und Modetheorie, Architektur und Raum, Körper und Bild, Erinnerungs- und Dingkultur</p><p><br></p><p>Im Fachbereich Gestaltung der Hochschule Trier werden viele unterschiedliche Fächer gelehrt, die alle ihre eigenen Gegenstandsbereiche und eigene Geschichte haben: Architektur, Innenarchitektur, Intermedia-Design, Kommunikationsdesign, Modedesign sowie Schmuck- und Edelsteindesign. Trotz der unterschiedlichen inhaltlichen Ausrichtung der einzelnen Fachrichtungen gibt es unter ihnen viele Beziehungen, Überschneidungen und Gemeinsamkeiten. Schließlich gehören sie alle zu einem breiten Feld kreativer Gestaltung, das noch durch die Bildende Kunst ergänzt wird.</p><p><br></p><p>Solchen Unterschieden und Gemeinsamkeiten im weiten Bereich der Gestaltung nachzugehen und ihre Genese sowie ihren historischen Wandel nachzuvollziehen, ist Ziel dieser Vorlesung. Sie liefert eine einführende Orientierung über die großen Epochen der Architektur-, Design- und Kunstgeschichte, angefangen bei der Antike über das Mittelalter und die Frühe Neuzeit bis zur Moderne. Ein inhaltlicher Schwerpunkt liegt auf der Geschichte der Moderne im 19. und 20. Jahrhundert, weil sie für die aktuelle Gestaltung von besonderer Bedeutung ist.</p>",
    "ger_lehrgebiet": "Design-, Kunst- und Kulturgeschichte"
  },
  "MD/staff-MD-Prof-Bettina-Maiburg-.json": {
    "filename": "MD/staff-MD-Prof-Bettina-Maiburg-.json",
    "fullname": "Prof. Bettina Maiburg",
    "email": ".Maiburg(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "MD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "master",
        "selecStgSem": "3"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Spezialisierung: Intuitive Designmethoden, Dreidimensionale Oberflächengestaltung</p>",
    "ger_lehrgebiet": "Grundlagen Gestaltung und Aufbau von Bekleidungskollektionen, Grundlagen künstlerischer Gestaltung, Kollektionserstellung und –planung, DOB, KIKO"
  },
  "MD/staff-MD-Prof-Christian-Bruns-.json": {
    "filename": "MD/staff-MD-Prof-Christian-Bruns-.json",
    "fullname": "Prof. Christian Bruns",
    "email": " bruns(at)hochschule-trier.de ",
    "sdef_studiengang_sV": [
      {
        "selecStg": "MD",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Spezialisierung: Digitalisierung in der Produktentwicklung, Kollektionsgestaltung und -planung, Smart Textiles, Wearables und Fashion Technologies, Modemarketing</p><p><br></p><p>Aktuell befinden sich die Modeindustrie und die damit vernetzten Branchen im Prozess&nbsp;der Digitalisierung: Hier sind innovative kreative Prozesse eng verflochten mit digitalen Technologien und&nbsp;deren Einsatz in der Modellentwicklung, dem Entwurf, der Darstellung und auch in der Realisation. Absolvent*innen der Fachrichtung Modedesign brauchen fundierte Kenntnisse im Bereich der traditionellen&nbsp;Techniken und mittlerweile ebenso fundierte Kenntnisse im digitalen Bereich, um auf die Anforderungen&nbsp;einer möglichst nachhaltig arbeitenden Modeindustrie vorbereit zu sein.</p><p><br></p><p>Daher richtet sich die neu&nbsp;ausgerichtete&nbsp;Professur&nbsp;an dem grundlegenden digitalen Wandel und den rasanten Veränderungen&nbsp;in dem Berufsfeld Modedesign aus, um neue technologische und strategische Möglichkeiten zu&nbsp;entwickeln. Gleichfalls soll traditionell, etabliertes Wissen fortführend vermittelt und erhalten werden,&nbsp;um daraus neue Wege der Gestaltung aufzuzeigen und kreative Schaffensprozesse und Entwurfsmethoden&nbsp;zu entwickeln.</p>",
    "ger_lehrgebiet": "Digitale Grundlagen Modedesign und 3D Modedesign-Prototyping"
  },
  "MD/staff-MD-Prof-Dirk-Wolfes-.json": {
    "filename": "MD/staff-MD-Prof-Dirk-Wolfes-.json",
    "fullname": "Prof. Dirk Wolfes",
    "email": " D.Wolfes(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "MD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "MD",
        "selecStgSem": "3"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Spezialisierung: DOB, Strick</p>",
    "ger_lehrgebiet": "Grundlagen Gestaltung und Aufbau von Bekleidungskollektionen, Grundlagen künstlerischer Gestaltung, gestalterischer Entwurf, Kollektionsgestaltung und-planung"
  },
  "MD/staff-MD-Prof-Sarah-Weisman-.json": {
    "filename": "MD/staff-MD-Prof-Sarah-Weisman-.json",
    "fullname": "Prof. Sarah Weisman",
    "email": "weismans(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "MD",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p><br></p>",
    "ger_lehrgebiet": "Grundlagen figürliches und freies Zeichnen, künstlerische Techniken und plastisches Gestalten"
  },
  "TD/staff-TD-Prof-Jörg-Obergfell-.json": {
    "filename": "TD/staff-TD-Prof-Jörg-Obergfell-.json",
    "fullname": "Prof. Jörg Obergfell",
    "email": "J.Obergfell@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "TD",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "TD"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Dezidiert künstlerisch und interdisziplinär ausgerichtet, zielt das Lehrgebiet „Künstlerisches Gestalten“ auf die individuelle Persönlichkeitsbildung und auf die Förderung des Austausches der Studierenden. Formal, inhaltlich und handwerklich bringt es künstlerische Impulse und Gesamtkonzeptionen in alle 6 Fachrichtungen des Fachbereichs Gestaltung ein. Diese umfassen die Bereiche Holzschnitzen, Bricolage, Rauminstallation, Kunst und Natur, Intervention und Medien wie Plastik, Fotografie, Video, Zeichnung sowie die Konzeption und Umsetzung von Ausstellungen.</p>",
    "ger_lehrgebiet": "Künstlerisches Gestalten"
  },
  "TD/staff-TD-Prof-Simon-Maris-.json": {
    "filename": "TD/staff-TD-Prof-Simon-Maris-.json",
    "fullname": "Prof. Simon Maris",
    "email": "maris@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "TD"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "TD"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Das Lehrgebiet \"KI - Design und Nachhaltigkeit\" ist auf die Verknüpfung von Künstlicher Intelligenz (KI) und Gestaltung im Kontext der Nachhaltigkeit spezialisiert. Hier werden vielfältige Aspekte der KI erforscht, die dazu dienen, nachhaltige Lösungen in Designprozessen zu entwickeln. Besonderer Wert wird auf die Verbindung von KI mit ethisch und ökologisch verantwortungsbewusstem Design gelegt. Das Lehrgebiet bereitet Studierende darauf vor, Künstliche Intelligenz als Werkzeug für die Schaffung einer nachhaltigen Zukunft durch Gestaltung zu nutzen.</p>",
    "ger_lehrgebiet": "KI - Design und Nachhaltigkeit"
  },
  "TD/staff-TD-Prof-Susanne-Bennewitz-.json": {
    "filename": "TD/staff-TD-Prof-Susanne-Bennewitz-.json",
    "fullname": "Prof. Susanne Bennewitz",
    "email": "S.Bennewitz@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStgSem": "7",
        "selecStg": "TD"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "TD"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Susanne Bennewitz vertritt das Fachgebiet in allen Studiengängen am Campus Gestaltung. Sie gehört zur Gruppe der transdisziplinär tätigen Professoren. Einerseits werden so gesellschaftspolitische, soziale und kulturwissenschaftliche Themen und Arbeitsweisen in der Fachlehre aller Studiengänge gefördert. Andererseits können in Kursen, die interdisziplinär ausgeschrieben sind, Studierende aus mehreren Fachrichtungen des Campus zu übergreifenden Themen und theoretischen Positionen im Design gemeinsam arbeiten.</p><p><br></p><p>Schwerpunkte liegen auf kultureller Diversität, Postkolonialismus, Genderaspekten, Szenografie, KI und Mobilität.</p>",
    "ger_lehrgebiet": "Gesellschafts- und Kulturwissenschaften"
  },
  "staff_kd/staff-Empl-KD-Achim-Andres_1716292523.json": {
    "filename": "staff_kd/staff-Empl-KD-Achim-Andres_1716292523.json",
    "fullname": "Achim Andres",
    "email": "andres@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Employee",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "N/A"
  },
  "staff_kd/staff-Empl-KD-Frank-Thieltges_1716294851.json": {
    "filename": "staff_kd/staff-Empl-KD-Frank-Thieltges_1716294851.json",
    "fullname": "Frank Thieltges",
    "email": "f.thieltges@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Employee",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "N/A"
  },
  "staff_kd/staff-Lect-KD-Michael-Zirlewagen_1716294114.json": {
    "filename": "staff_kd/staff-Lect-KD-Michael-Zirlewagen_1716294114.json",
    "fullname": "Michael Zirlewagen",
    "email": "M.Zirlewagen@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      }
    ],
    "eng_function": "Lecturer",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "N/A"
  },
  "staff_kd/staff-Lect-KD-Nicholas-Tänzer_1716292829.json": {
    "filename": "staff_kd/staff-Lect-KD-Nicholas-Tänzer_1716292829.json",
    "fullname": "Nicholas Tänzer",
    "email": "n.taenzer@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      }
    ],
    "eng_function": "Lecturer",
    "ger_descr_lehrgebiet": "N/A",
    "ger_lehrgebiet": "N/A"
  },
  "staff_kd/staff-Prof-KD-Babak Mossa-Asbagholmodjahedin_1716292918.json": {
    "filename": "staff_kd/staff-Prof-KD-Babak Mossa-Asbagholmodjahedin_1716292918.json",
    "fullname": "Prof. Babak Mossa Asbagholmodjahedin",
    "email": "boback@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Das Lehrgebiet Zeichnen und Medien legt seinen Fokus auf die Entwicklung künstlerisch-gestalterischer Fähigkeiten unter Anwendung traditioneller und neuer Techniken. Die Entwicklung einer fundierten und kritischen Sicht auf die Entwicklung der Kommunikations- und Medienindustrie, sowie die Erarbeitung interdisziplinärer Gestaltungsaspekte und Berücksichtigung multipler Medien und der Synergieeffekte sind zentrale Themen. Neben der Erarbeitung zeichnerischer Lösungen steht auch die symbiotische Verwendung von medienübergreifenden Techniken, Interaktionen, Bewegtbild, linearen oder nonlinearen Erzählstrukturen im Vordergrund.</p>",
    "ger_lehrgebiet": "Zeichnen und Medien"
  },
  "staff_kd/staff-Prof-KD-Christopher-Ledwig_1716292973.json": {
    "filename": "staff_kd/staff-Prof-KD-Christopher-Ledwig_1716292973.json",
    "fullname": "Prof. Christopher Ledwig",
    "email": "ledwig@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Das Lehrgebiet Digitale Medien beschäftigt sich mit deren Grundlagen und den sich stets weiterentwickelnden Potentialen. Hier wird die theoretische Basis stets mit praktischen Umsetzungen kombiniert, indem Studierende nicht nur die strategische Herangehensweise (User Experience) vermittelt bekommen, sondern sie auch aktiv in Form von Gestaltungen von Benutzeroberflächen (Interaction Design) für digitalen Medien in Anwendung bringen. Entgegen dem klassischen Seitenlayout und der Beherrschung der Fläche vermittelt die Lehre die Entwicklung flexibler visueller Systeme von Komponenten, welche sich adaptiv zum stark variierenden Format der Nutzer Endgeräte verhalten.</p><p><br></p><p>Der Fokus des Lehrgebietes liegt hierbei weniger auf den programmiertechnischen sondern auf den konzeptionellen und gestalterischen Aspekten sowie prototypischen Umsetzungen. In einem Labor für digitale Medien steht Hardware für einen spielerischen Umgang und praktische Experimente zur Verfügung.</p>",
    "ger_lehrgebiet": "Digitale Medien"
  },
  "staff_kd/staff-Prof-KD-Dirk-Wachowiak_1716293050.json": {
    "filename": "staff_kd/staff-Prof-KD-Dirk-Wachowiak_1716293050.json",
    "fullname": "Prof. Dirk Wachowiak",
    "email": "d.wachowiak@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "KD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Typografie beschäftigt sich mit der Visualisierung von Sprache und der angemessenen Handhabung von Schrift in unterschiedlichen Medien (Größe, Farbe, Unterschneidung, Position, …). Ebenso kann Typografie aber auch&nbsp;einen Inhalt interpretieren und ausdrucksstark zur Geltung kommen, wie z.B. bei einem Kulturplakat. Die Handhabung und Einschätzung von Schriften werden im Rahmen von eigenen digitalen Schriftentwürfen geübt.</p>",
    "ger_lehrgebiet": "Typografie und Corporate Design"
  },
  "staff_kd/staff-Prof-KD-Harald-Steber_1716293207.json": {
    "filename": "staff_kd/staff-Prof-KD-Harald-Steber_1716293207.json",
    "fullname": "Prof. Harald Steber",
    "email": "steber@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Objekte kommunizieren mit Betrachtern und Nutzenden über ihre grafische Gestaltung, Materialien, Formgebung, ja sogar ihr Gewicht und ihren Geruch. Und sobald ein Entwurf physisch umgesetzt wird, besteht er aus Materialien und benötigt Verarbeitungsmethoden, die einen grösseren oder kleineren ökologischen und sozialen Fussabdruck haben.</p><p>In meiner Lehre vermittele ich Herangehensweisen, die Gestaltern den bewussten Einsatz von Layout, Material und Verarbeitung zur Entwicklung von Produkten und Produktpräsentationen ermöglichen, die markengerecht mit Nutzern kommunizieren. Diese gestalteten Elemente sollen sowohl bei ihrer Produktion, während der Nutzung, aber auch an ihrem Lebensende möglichst geringe negative Umwelteinwirkungen haben.</p>",
    "ger_lehrgebiet": "3DKD - Dreidimensionales Kommunikationsdesign"
  },
  "staff_kd/staff-Prof-KD-Henriette-Sauvant_1716304595.json": {
    "filename": "staff_kd/staff-Prof-KD-Henriette-Sauvant_1716304595.json",
    "fullname": "Prof. Henriette Sauvant",
    "email": "h.sauvant@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "KD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Die Kombination von Illustration und Buchgestaltung eröffnet vielfältige Möglichkeiten des visuellen Erzählens in einer literarischen Form. Auf den Seiten eines Buches entsteht eine Dramaturgie von Gestaltungselementen in Bild und Text. Zeitgenössische Bilderzählungen werden unter Vermeidung von Stereotypen und Klischees entwickelt.</p><p><br></p><p>Im zweiten Semester geht es um Grundlagen der Bildgestaltung, z.B. das künstlerische Experiment, verschiedene Techniken des Zeichnens oder Malens und den Einsatz von Farbe. Der Kurs im dritten Semester ist eine Einführung in die Buchgestaltung. Die Themen sind zum Beispiel: Illustration, experimentelle Wege zur Gestaltung eines narrativen Bildes, Linolschnitt, Buchbinderei, Arbeit mit Text und Typografie.</p><p><br></p><p>Die Kurse des vierten Semesters befassen sich mit komplexen Aufgabenstellungen und setzen gute Kenntnisse der Grundlagen der Illustration und Typografie voraus. Für die Entwicklung von Gestaltungsprojekten stehen die Buchbinderei und die originalgrafischen Drucktechniken zur Verfügung, ebenso das Digitaldruckzentrum.</p>",
    "ger_lehrgebiet": "Buchillustration und Buchgestaltung"
  },
  "staff_kd/staff-Prof-KD-IA-Gerald-Schröder_1716292668.json": {
    "filename": "staff_kd/staff-Prof-KD-IA-Gerald-Schröder_1716292668.json",
    "fullname": "Prof. Dr. habil. Gerald Schröder",
    "email": "G.Schroeder@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "KD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "bachelor",
        "selecStgSem": "8"
      },
      {
        "roleItem": "",
        "selecStg": "KD",
        "selecStgType": "master",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStg": "KD",
        "selecStgType": "master",
        "selecStgSem": "4"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "IA",
        "selecStgSem": "2"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>In meinen Lehrveranstaltungen verbinde ich die historische Perspektive der Design- und Kunstgeschichte mit systematischen kategorialen Aspekten der Design- und Kunstwissenschaft. Beides beziehe ich auf aktuelle Fragestellungen der Gestaltung, damit sich die Studierenden im heutigen komplexen Feld gestalterischer Praxis orientieren und sich mit ihrer eigenen Arbeit selbstreflexiv positionieren können. Dabei ist es mir ein besonderes Anliegen, den Studierenden die gesellschaftspolitische Relevanz und Bedingtheit ihrer Arbeit vor Augen zu führen. Deswegen spielen in meinem Unterricht auch die größeren gesellschaftlichen und kulturellen Kontexte der Gestaltung eine zentrale Rolle.</p>",
    "ger_lehrgebiet": "Design und Kunstwissenschaft"
  },
  "staff_kd/staff-Prof-KD-Tim-Romanowsky_1716292395.json": {
    "filename": "staff_kd/staff-Prof-KD-Tim-Romanowsky_1716292395.json",
    "fullname": "Prof. Tim Romanowsky",
    "email": "T.Romanowsky@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>In den Kursen für Gestaltungsgrundlagen steht vor allem das Experimentieren im Vordergrund. Mit Farb- und Formexperimenten werden Grenzen ausgelotet und durch Übungen mit verschiedenen Werkzeugen, Formaten und Kompositionen wird eine gestalterische Eigenständigkeit entwickelt. In den höheren Semestern kommen die Grundlagen der Animation hinzu, die ab dem 3. Semester vertieft werden können. In der eigenständigen Entwicklung von thematischen Bildserien/-welten können in den Kursen kurze Animationsfilme oder Publikationen entstehen. Die Studierenden sollen ermutigt werden, eine eigene Handschrift zu entwickeln, um damit selbstbewusst und zielgerichtet arbeiten zu können. Unterstützt werden die Studierenden dabei durch die Vermittlung von praxisnahen analogen und digitalen Werkzeugen zur Darstellung von Figur und Objekt.</p>",
    "ger_lehrgebiet": "Experimentelle Gestaltung"
  },
  "staff_kd/staff-Prof-KD-Valerie-Schmidt_1716293503.json": {
    "filename": "staff_kd/staff-Prof-KD-Valerie-Schmidt_1716293503.json",
    "fullname": "Prof. Valerie Schmidt",
    "email": "v.schmidt@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "KD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Im Lehrgebiet transmediale fotografische Narration und Bildgestaltung werden künstlerische und angewandte Bildstrategien medialer Vielfalt als Ursprung für fotografische oder Fotografie ähnliche Bilderzeugnisse in statischer oder bewegter Form vermittelt.</p><p><br></p><p>Diverse Bilderzeugungsmöglichkeiten werden erprobt und angewendet und es wird mit verschiedenen Publikations- und Ausstellungsstrategien gearbeitet. Aufnahme- und Selektionsprozess, sowie die Rezeption sollte als Teil des finalen Werkes verstanden werden. Stille oder bewegte Bilder werden seriell und selektiv arrangiert und außerhalb ihrer technischen Gesinnung besprochen und beschrieben.</p><p><br></p><p>Erwünscht ist der Erwerb einer Kernkompetenz für eine professionelle fotografisch-visuelle Praxis im Kontext von Kunst, Design, Medien und Gesellschaft. Diese sollte verwundernd, erzählend oder zerstreuend sein, Beziehungen herstellen oder autonom sein und sich transmedial entwickeln oder gar verwickeln.</p>",
    "ger_lehrgebiet": "Transmediale fotografische Narration und Bildgestaltung"
  },
  "staff_kd/staff-Prof-TD-ID-MD-AR-KD-Susanne-Bennewitz_1716293651.json": {
    "filename": "staff_kd/staff-Prof-TD-ID-MD-AR-KD-Susanne-Bennewitz_1716293651.json",
    "fullname": "Prof. Dr.  Susanne Bennewitz",
    "email": "S.Bennewitz@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStgSem": "7",
        "selecStg": "TD"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStgSem": "4",
        "selecStg": "TD"
      },
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "ID",
        "selecStgSem": "2"
      },
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "MD",
        "selecStgSem": "6"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "bachelor",
        "selecStgSem": "6"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "AR",
        "selecStgSem": "4"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStg": "KD",
        "selecStgSem": "4",
        "selecStgType": "master"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor",
    "ger_descr_lehrgebiet": "<p>Susanne Bennewitz vertritt das Fachgebiet in allen Studiengängen am Campus Gestaltung.</p><p>In fachspezifischen Kursen unterrichte ich kulturwissenschaftliche Methoden und soziale und politische Themen des Studiengangs.</p><p>In Kursen mit Studierenden aus verschiedenen Studiengängen erarbeiten wir Schnittpunkte, gemeinsame Themen und theoretische Positionen im Design.</p><p>In meinen Kursen steht die Entwicklung grundlegender Kompetenzen anhand von Beispielen im Mittelpunkt. Es liegt weniger Gewicht auf der einzelnen Umsetzung oder Problemlösung. Damit entsteht Freiraum für Fragen, Widersprüche, Reflektion und Dialoge.</p><p>Ich stärke folgende Themen in der Design- und Architekturausbildung: kulturelle Diversität, Postkolonialismus, Intersektionalität, Szenografie, Mobilität, Nachhaltigkeit und KI.</p>",
    "ger_lehrgebiet": "Gesellschafts- und Kulturwissenschaften"
  }
};