import React from "react";
import MdInput from "../MD-Input";

const moduleinfo = () => {
	return (
		<div>
			<div className="mt-5"></div>
			<h1>
				Module
				<MdInput></MdInput>
			</h1>
		</div>
	);
};

export default moduleinfo;
