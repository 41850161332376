import React from "react";
import MDStaffInput from '../MD-Staff-Input';


const staffinfo = () => {
	return (
		<div>
			<div className="mt-5"></div>
			<h1>
				Mitarbeiterinformation

				<MDStaffInput/>
			</h1>
		</div>
	);
};

export default staffinfo;
