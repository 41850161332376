import React from 'react';
import { useField } from 'formik';
import { FormGroup, FormLabel, Button } from 'react-bootstrap';

const FileInput = ({ label, setBase64, enableBase64 = false, setFilePreview, setImageResolution, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      helpers.setValue(file);
  
      const url = URL.createObjectURL(file);
      setFilePreview(url);
  
      // Load the image to read its resolution
      const img = new Image();
      img.onload = () => {
        if (img) {  // Check if the image object is not null
          const resolution = { width: img.width, height: img.height };
          if (setImageResolution) {
            setImageResolution(resolution);
          }
        }
      };
      img.onerror = () => {
        // Handle the error case
        if (setImageResolution) {
          setImageResolution(null);
        }
      };
      img.src = url;
  
      if (enableBase64) {
        try {
          const base64String = await convertToBase64(file);
          if (setBase64) {
            setBase64(base64String);
          }
        } catch (error) {
          console.error("Error during file conversion to Base64", error);
        }
      }
    } else {
      helpers.setValue(null);
      setFilePreview(null);
      if (setBase64) {
        setBase64(null);
      }
      if (setImageResolution) {
        setImageResolution(null);
      }
    }
  };
  

  return (
    <FormGroup>
      <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>
      <input
        id={props.id || props.name}
        {...props}
        type="file"
        onChange={handleFileChange}
        onBlur={field.onBlur}
        style={{ display: 'none' }} // Hide the default input
      />
      <Button
        as="label"
        htmlFor={props.id || props.name}
        variant="primary"
      >
        Datei auswählen
      </Button>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </FormGroup>
  );
};

export default FileInput;
