import React from "react";
import ReactDOMServer from "react-dom/server";
import { generateExportNaming } from "../func-generateExportNaming.js";
import { getUnixTimestamp } from "../func-getunixtimestamp.js";
import { parseTimestamp } from "../const-seafile.js";
import TemplateInsta from "../../components/templates/temp-insta.js";
import TemplateYoutube from "../../components/templates/temp-youtube.js";
import JSZip from "jszip";
import { generatexml } from "../../functions/abgabepaket/func-abga-generatexml.js";
import PagedFactsheet from "../../components/abgabepaket/comp-genpdf.jsx";
import html2pdf from "html2pdf.js";

export async function abgahandleSubmit(
  values,
  setValues,
  setModalShow,
  setDownloadLink
) {
  const ZIPfilename = generateExportNaming(values);
  const zip = new JSZip();
  const addedFilenames = new Set();

  // Dateien einsammeln
  Object.keys(values).forEach((key) => {
    if (values[key] instanceof File) {
      const fileField = key;
      const filenameField = `${fileField}_filename`;
      const file = values[fileField];
      const fileExt = file.name.split(".").pop();
      let index = fileField.match(/_\d+$/) ? "" : "_1";

      const cleanFileName = values[filenameField]
        ? values[filenameField].replace(/\.\w+$/, "")
        : `${ZIPfilename}_${fileField}${index}`;
      const newFileName = `${cleanFileName}.${fileExt}`;

      if (!addedFilenames.has(newFileName)) {
        zip.file(newFileName, file);
        values[filenameField] = newFileName;
        addedFilenames.add(newFileName);
      }
    }
  });

  const timestamp = getUnixTimestamp();
  const combinedData = {
    ...values,
    timestamp,
    parsed_timestamp: parseTimestamp(timestamp),
    filename: `${ZIPfilename}_${timestamp}`,
  };

  zip.file(`${ZIPfilename}_data.json`, JSON.stringify(combinedData, null, 2));
  zip.file(`${ZIPfilename}_data.xml`, generatexml(combinedData));

  // 🧪 PDF Generierung via Paged.js + html2pdf im iframe
  const html = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8" />
        <script src="https://unpkg.com/pagedjs/dist/paged.polyfill.js"></script>
        <style>
          @page { margin: 2cm; size: A4; }
          body { font-family: sans-serif; font-size: 11pt; color: #333; padding: 1rem; }
        </style>
      </head>
      <body>
        ${ReactDOMServer.renderToStaticMarkup(<PagedFactsheet values={combinedData} />)}
      </body>
    </html>
  `;

  // 🖼️ Iframe für Rendering erzeugen
  const iframe = document.createElement("iframe");
  iframe.style.cssText = "position: fixed; top: -9999px; left: -9999px; width: 0; height: 0; opacity: 0;";
  document.body.appendChild(iframe);

  const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

  // HTML einfügen
  iframeDoc.open();
  iframeDoc.write(html);
  iframeDoc.close();

  // 🔄 Warten bis paged.js fertig ist
  await new Promise((resolve) => {
    const checkReady = () => {
      const win = iframe.contentWindow;
      const paged = win.PagedPolyfill;
      if (paged && typeof paged.on === "function") {
        paged.on("rendered", resolve);
      } else {
        setTimeout(checkReady, 50);
      }
    };
    checkReady();
  });

  // ⬇️ Exportieren mit html2pdf
  const factsheetBlob = await html2pdf()
    .from(iframe.contentDocument.body)
    .set({
      margin: 0,
      filename: `${ZIPfilename}_factsheet.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    })
    .outputPdf("blob");

  zip.file(`${ZIPfilename}_factsheet.pdf`, factsheetBlob);
  document.body.removeChild(iframe); // Aufräumen

  // Optional: Textdateien
  if (values["check_youtube_allowance"]) {
    const yt = ReactDOMServer.renderToStaticMarkup(<TemplateYoutube values={values} />);
    zip.file(`${ZIPfilename}_youtube.txt`, yt);
  } else {
    zip.file(`${ZIPfilename}_DO-NOT-PUBLISH-ON-YOUTUBE.txt`, "Do not publish on YouTube");
  }

  if (values["url_insta_allowance"]) {
    const insta = ReactDOMServer.renderToStaticMarkup(<TemplateInsta values={values} />);
    zip.file(`${ZIPfilename}_insta.txt`, insta);
  }

  // 📦 ZIP Download starten
  zip.generateAsync({ type: "blob" }).then((blob) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${ZIPfilename}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setModalShow(true);
    setDownloadLink(link.href);
  });
}
