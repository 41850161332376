import React, { useState } from 'react';

import LLM_CONFIG from '../../config/llmConfig-Mistral';

const LlmFeedbackMistral = ({ values, PromptTemplate, extraPrompt = '', imageDescriptions = [] }) => {
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSend = async () => {
    const { systemPrompt, userPrompt } = PromptTemplate({
      values,
      extraPrompt,
      images: imageDescriptions,
    });

    const messages = [
      { role: 'system', content: systemPrompt },
      { role: 'user', content: userPrompt },
    ];

    setLoading(true);
    setFeedback('');
    setError('');

    try {
      const response = await fetch(LLM_CONFIG.API_URL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${LLM_CONFIG.API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: LLM_CONFIG.MODEL,
          messages,
          temperature: LLM_CONFIG.TEMPERATURE,
        }),
      });

      const data = await response.json();
      console.log('RAW Mistral API Response:', data);

      const reply = data.choices?.[0]?.message?.content;

      if (reply) {
        setFeedback(reply);
      } else if (data.error) {
        setError(`❌ API Fehler: ${data.error.message}`);
      } else {
        setError('❌ Keine Antwort von der API erhalten.');
      }
    } catch (err) {
      console.error('Mistral API error:', err);
      setError('⚠️ Fehler beim Abrufen der Rückmeldung.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
        Mistral:
      <button onClick={handleSend} disabled={loading}>
        {loading ? 'Lade Feedback...' : 'Feedback generieren'}
      </button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {feedback && (
        <div style={{ whiteSpace: 'pre-line', marginTop: '1rem', border: '1px solid #ddd', padding: '1rem' }}>
          {feedback}
        </div>
      )}
    </div>
  );
};

export default LlmFeedbackMistral;
