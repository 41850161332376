export function generatexml(data) {
    const INDENT = "  ";
  
    const buildXml = (obj, nodeName = "projekt", indentLevel = 1) => {
      const indent = INDENT.repeat(indentLevel);
      let xml = `${indent}<${nodeName}>\n`;
  
      for (const key in obj) {
        const value = obj[key];
  
        // Skip functions (e.g., arrayBuffer, stream, etc.)
        if (typeof value === "function") continue;
  
        const keyTag = `${indent}${INDENT}<${key}>`;
        const keyClose = `</${key}>\n`;
  
        if (Array.isArray(value)) {
          xml += `${indent}${INDENT}<${key}>\n`;
          for (const item of value) {
            xml += buildXml(item, "item", indentLevel + 2);
          }
          xml += `${indent}${INDENT}</${key}>\n`;
  
        } else if (typeof value === "object" && value !== null) {
          xml += buildXml(value, key, indentLevel + 1);
  
        } else if (value === null) {
          xml += `${keyTag}None${keyClose}`;
  
        } else {
          const escaped = String(value)
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");
          xml += `${keyTag}${escaped}${keyClose}`;
        }
      }
  
      xml += `${indent}</${nodeName}>\n`;
      return xml;
    };
  
    return `<?xml version="1.0" encoding="UTF-8"?>\n<root>\n${buildXml(data)}\n</root>`;
  }
  